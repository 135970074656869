<template>
  <div>
    <div
      class="quescard d-flex relativeclass"
      :id="'ques' + data.questionKey + selectedSection.sectionID"
    >
      <div
        class="quescard-colorbar ml-2"
        :class="
          data.answerStatus.later
            ? 'side-blocks-later'
            : data.answerStatus.attempted
            ? 'side-blocks-attempted'
            : data.questionBody.config.config.required
            ? 'side-blocks-active'
            : 'side-blocks-unattempted'
        "
      ></div>
      <div class="d-flex flex-column mcqcard">
        <b-row class="mb-2">
          <b-col>
            <div class="d-flex align-items-center">
              <div class="quescard-mainhead">Descriptive</div>
              <div
                class="marktagbox marginmcq"
                v-if="testDetails.config.dispMarks"
              >
                {{ data.questionBody.marks }} Marks
              </div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <template v-if="testDetails.config.dispReqStatus">
        <div
          class="reqd-txt mr-3"
          v-if="data.questionBody.config.config.required"
        >
          <img
            src="../../assets/newimgs/testscreen/reqQuesIcon.svg"
            alt=""
            class=""
          />
          Required
        </div>
      </template>
            <div
              class="markforlatercheckbox dodontcheckbox d-flex align-items-center justify-content-end"
            >
              <b-form-checkbox
                name="checkbox-1"
                v-model="data.answerStatus.later"
                @change="markforLater()"
              >
                Mark for later
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex flex-column">
          <div class="d-flex">
            <div class="sec-ques-style ">Q{{ showindex }}.</div>
            <div
              class="ml-1 sec-ques-style topicDescBreak"
              v-html-safe="data.questionBody.question"
            ></div>
          </div>
          <div v-if="data.questionBody.questionMedia != null" class="mb-3">
            <div
              v-if="isImage(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <img
                class="qb-image-preview"
                :src="data.questionBody.questionMedia"
                alt=""
              />
            </div>
            <div
              v-if="isAudio(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <Media
                :kind="'audio'"
                :isMuted="false"
                :src="data.questionBody.questionMedia"
                :autoplay="false"
                :controls="true"
                :loop="true"
                class="mcqdatacard-audio"
              ></Media>
            </div>
            <div
              v-if="isVideo(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <Media
                :kind="'video'"
                :isMuted="false"
                :src="data.questionBody.questionMedia"
                :autoplay="false"
                :controls="true"
                :loop="true"
                class="qb-image-preview"
              ></Media>
            </div>
          </div>
          <div class="p-2">
            <b-form-textarea
              id="textarea"
              @update="textSearch($event)"
              debounce="500"
              v-model.trim="data.answer"
              rows="3"
              max-rows="6"
              @copy="preventCopyPaste"
              @cut="preventCopyPaste"
              @paste="preventCopyPaste"

            ></b-form-textarea>
          </div>
        </div>
      </div>
      
    </div>
    <div v-if="cutcpoypaste"
      class="notification d-flex justify-content-between align-items-center mr-2"
    >
      <div style="color: white" class="ml-2">Cut copy paste is not allowed</div>
      <div class="pointer mr-2 close-notify">
        <img
          @click="closeNotification()"
          src="../../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import { isImage, isVideo, isAudio } from "../../checkFileType.js";
import Media from "@dongido/vue-viaudio";
import { mapGetters } from "vuex";

export default {
  name: "longAnswercard",
  components: {
    Media,
  },

  props: {
    data: Object,
    showindex: Number,
  },
  data() {
    return {
      showMarks: null,
      showReqStatus: null,
      timer: null,
      cutcpoypaste:false
    };
  },

  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
    ...mapGetters("testVUEX", {
      selectedSection: "getSelectedSection",
    }),
  },
  mounted() {
    this.showMarks = this.testDetails.config.dispMarks;
    this.showReqStatus = this.testDetails.config.dispReqStatus;
  },
  methods: {
    isImage,
    isVideo,
    isAudio,
    markforLater() {
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.data.questionKey,
        answer: this.data.answer,
        answerStatus: this.data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    preventCopyPaste(event) {
      event.preventDefault();
      this.cutcpoypaste = true
      setTimeout(() => {
      this.cutcpoypaste = false
      }, 4000);
    },
    closeNotification(){
      this.cutcpoypaste = false
    },
    textSearch(e) {
      console.log(e);
        this.saveCurrentAnswer();
    },
    debounce(callback, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          callback.apply(this, args);
        }, wait);
      };
    },
    saveCurrentAnswer() {
      console.log("ccol");
      if (this.data.answer == "") {
        this.data.answerStatus.active = true;
        this.data.answerStatus.unattempted = true;
        this.data.answerStatus.attempted = false;
      } else {
        this.data.answerStatus.active = true;
        this.data.answerStatus.unattempted = false;
        this.data.answerStatus.attempted = true;
      }
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.data.questionKey,
        answer: this.data.answer,
        answerStatus: this.data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    answerDisplay() {
      this.data.answerStatus.unattempted = false;
      this.data.answerStatus.attempted = true;

      this.$store.commit("questionVUEX/saveCurrentAnswer", this.data);
    },
  },
};
</script>
<style>
@import "../../assets/globalcss/style.css";
.close-notify {
  background: white;
  border-radius: 50px;
}
.notification {
  position: fixed;
  top: 8%;
  border-radius: 5px;
  width: 260px;
  height: 50px;
  background: red;
  text-align: center;
  right: -128px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  animation: slideIn 0.5s ease-out forwards;
}
@keyframes slideIn {
  from {
    top: -100px;
  }
  to {
    top: 8%;
  }
}
.required-mark {
  background: #c92b2b;
  width: 25px;
  padding: 4px;
  margin-top: -21px;
  border-radius: 0px 0px 12px 12px;
}
.fa-star {
  color: white;
}
</style>
