<template>
  <!-- -->
  <div id="fullscreenElement"  @contextmenu.prevent="preventRightClick">
    <div class="p-0">
      <div v-if="Loaderstatus" class="relativeclass">
        <div class="testscreen-spinnerstyle">
          <Spinner />
        </div>
      </div>
      <template>
  
        <div class="test-main-container">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="test-headtext mb-0" style="width: 45%;">
              <div class="text-truncate mr-2" @click="show = true">
                {{ testDetails.name }}
              </div>
            </div>
            <div style="width: 30%" class="ml-4">
              <div
                :class="timeinsecondsfornotificationfunc.style"
                class="test-timerdiv-active d-flex"
                v-if="testDetails.config.dispDuration"
              >
                <div class="d-flex align-items-center ml-3">
                  <img
                    v-if="
                      timeinsecondsfornotificationfunc.imageName == 'whitetimer'
                    "
                    src="../assets/newimgs/whitetimer1.svg"
                    alt=""
                  />
                  <img v-else src="../assets/newimgs/whitetimer1.svg" alt="" />
                </div>
                <div class="d-flex timer-txt-new align-items-center ml-2 mr-4">
                  <span>{{ testTimer }}</span>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-end bluebtns"
              style="width: 25%;"
            >
              <b-button
                class="px-5"
                v-if="testDetails.config.includeEndTest"
                variant="primary"
                @click="endTestPost()"
              >
                Finish Test</b-button
              >
            </div>
          </div>

          <div class="d-flex" v-if="!tryQuestionvariable">
            <div
              class="test-left-container p-0 relativeclass"
              :class="openCodingSection ? 'p-0' : ''"
            >
              <div v-if="!openCodingSection" class="">
                <div class="p-3">
                  <SectionFilling />
                </div>
                <hr class="my-0" />
                <div class="test-footer p-3">
                  <div class="d-flex">
                    <div style="width: 20%" class="bluebtns">
                      <b-button
                        variant="outline-primary"
                        @click="previous(), scrollToBottom()"
                        v-if="!prevBtn"
                      >
                        Previous Section
                      </b-button>
                    </div>
                    <div
                      v-if="selectedSection.sectionName != 'programming'"
                      style="width: 60%"
                      class="d-flex align-items-center justify-content-center"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center pagination-icon"
                        :class="
                          lastQuestionIndex == 0 ||
                          lastQuestionIndex == noOfQues
                            ? 'nxticon-disable'
                            : 'nxticon'
                        "
                      >
                        <b-button
                          id="prevbtnb"
                          variant="outline"
                          @click="prevPag(), scrollToBottom()"
                          :disabled="
                            lastQuestionIndex == 0 ||
                              lastQuestionIndex == noOfQues
                          "
                        >
                          <img
                            src="../assets/icons/testscreen/previcon.svg"
                            alt=""
                        /></b-button>
                      </div>
                      <div class="test-pagetext ml-3 mr-3">
                        Page {{ noPage }} / {{ noOfPages }}
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center"
                        :class="
                          noPage == noOfPages
                            ? 'nxticon-disable'
                            : 'nxticon pagination-icon'
                        "
                      >
                        <b-button
                          id="nxtbtnb"
                          variant="outline"
                          :disabled="noPage == noOfPages"
                          @click="nextPag(), scrollToBottom()"
                          ><img
                            src="../assets/icons/testscreen/nxticon.svg"
                            alt=""
                        /></b-button>
                      </div>
                    </div>
                    <div
                      style="width: 20%"
                      class="d-flex justify-content-end bluebtns"
                    >
                      <b-button
                        @click="nextSection(), scrollToBottom()"
                        v-if="!nextBtn"
                        variant="primary"
                      >
                        Next Section
                      </b-button>
                    </div>
                  </div>
                </div>
                <video id="proctor" autoplay muted></video>
                <canvas
                  id="proctoring"
                  width="200"
                  height="150"
                  hidden
                ></canvas>
              </div>
              <div v-else class="h-100">
                <TakeQuestions />
              </div>
            </div>
            <div class="test-right-container relativeclass">
              <div class="test-rightside-text d-flex justify-content-center">Overview</div>
              <div>
                <ProgressBar />
              </div>
            </div>
          </div>
          <div v-else>
            <TryQuestion />
          </div>
        </div>
      </template>
      <b-modal
        id="cantopentab"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>
        <div
          @click="$bvModal.hide('cantopentab')"
          class="modalcloseicon-camacc pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newui-img/test-screen/fullscn-alert.svg"
              alt=""
            />
          </div>
          <div class="line2-txt mt-4">
            Please note that you cannot open a new tab or window while taking
            the test.
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="$bvModal.hide('cantopentab')"
              >Okay</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="cantnavigate"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>
        <div
          @click="$bvModal.hide('cantnavigate')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newimgs/alerticon.svg" alt="" />
          </div>
          <div class="newmodal-body-content mt-4">
            Please note you cannot navigate while you are taking test.
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="$bvModal.hide('cantnavigate')"
              >Okay</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="cantendtest"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>
        <div
          @click="$bvModal.hide('cantendtest')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newimgs/alerticon.svg" alt="" />
          </div>
          <div class="newmodal-body-content mt-4">
            Please note you are not supposed to end the test untill all the
            required questions has been answered.
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="$bvModal.hide('cantendtest')"
              >Okay</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="finishtestmodal"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>
        <div
          @click="$bvModal.hide('finishtestmodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newimgs/testscreen/testscreenmodal.svg"
              alt=""
            />
          </div>
          <div class="newmodal-body-content-ques mt-4">
            Are you sure you want to finish the test ?
          </div>
          <div class="newmodal-body-content mt-4">
            Please note this action cannot be undone
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="outline-primary"
              class="mr-3"
              @click="$bvModal.hide('finishtestmodal')"
              >Cancel</b-button
            >
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="(Loaderstatus = true), finishTestStopt()"
              >Finish Test</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="latermodal"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert check!
        </div>
        <div
          @click="$bvModal.hide('latermodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newimgs/testscreen/testscreenmodal.svg"
              alt=""
            />
          </div>
          <div
            class="newmodal-body-content mt-4"
            v-html-safe="laterModalText"
          ></div>
          <div class="bluebtns mt-5">
            <b-button
              class="mr-3 pl-4 pr-4"
              variant="outline-primary"
              @click="hideLaterModal()"
              >Cancel</b-button
            >
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="(Loaderstatus = true), finishtestlater()"
              >Finish Test</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="alertmodal"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>

        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newui-img/test-screen/fullscn-alert.svg"
              alt=""
            />
          </div>
          <div class="line2-txt mt-4">
            This test requires full screen mode.
          </div>
          <div class="bluebtns mt-4">
            <b-button
              variant="primary"
              class="px-5"
              @click="$bvModal.hide('alertmodal'), fullScreenMode()"
              >Okay</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="latermodal" class="testscreen-custom-modal">
      <div class="testscreen-modal-content">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>
        <div @click="latermodal = false" class="modalcloseicon pointer">
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newui-img/test-screen/finish-testscreen.svg"
              alt=""
            />
          </div>
          <div class="line1-txt mt-4" v-html-safe="laterModalText"></div>
          <div class="bluebtns mt-5">
            <b-button
              class="mr-3 pl-4 pr-4"
              variant="outline-primary"
              @click="latermodal = false"
              >Cancel</b-button
            >
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="(Loaderstatus = true), finishtestlater()"
              >Finish Test</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="finishtestmodal" class="testscreen-custom-modal">
      <div class="testscreen-modal-content">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert !
        </div>
        <div @click="finishtestmodal = false" class="modalcloseicon pointer">
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newimgs/testscreen/testscreenmodal.svg"
              alt=""
            />
          </div>
          <div class="line1-txt mt-4">
            Are you sure you want to finish the test ?
          </div>
          <div class="line2-txt ">
            Please note this action cannot be undone
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="outline-primary"
              class="mr-3"
              @click="finishtestmodal = false"
              >Cancel</b-button
            >
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              @click="(Loaderstatus = true), finishTestStopt()"
              >Finish Test</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="cantendtest" class="testscreen-custom-modal">
      <div class="testscreen-modal-content">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert
        </div>
        <div
          @click="cantendtest = false"
          class="modalcloseicon-camacc  pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newui-img/test-screen/fullscn-alert.svg"
              alt=""
            />
          </div>
          <div class="line2-txt mt-4 w-75">
            Please note you are not supposed to end the test untill all the
            required questions has been answered.
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="primary"
              class="px-5"
              @click="cantendtest = false"
              >Okay</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="cantnavigate" class="testscreen-custom-modal">
      <div class="testscreen-modal-content">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert !
        </div>
        <div @click="cantnavigate = false" class="modalcloseicon pointer">
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img
              src="../assets/newui-img/test-screen/fullscn-alert.svg"
              alt=""
            />
          </div>
          <div class="line2-txt mt-4">
            Please note you cannot navigate while you are taking test.
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="primary"
              class="px-5"
              @click="cantnavigate = false"
              >Okay</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="cameramodal" class="testscreen-custom-modal">
      <div class="testscreen-modal-content-camacc">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Allow Camera Access
        </div>
        <div @click="startStream()" class="modalcloseicon-camacc pointer">
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div class="newmodal-body-content ">
            You cannot able to continue with test untill you turn on camera
          </div>
          <div class="mt-5 mb-3 img-shadow">
            <img
              src="../assets/newui-img/test-screen/cam-access-img.svg"
              alt=""
            />
          </div>
          <div class="newmodal-body-content mt-4">
            Once you enable the camera, please reload the page
          </div>
          <img
            class="mt-4 img-shadow"
            style="width: 83%;"
            src="../assets/images/step3.svg"
            alt=""
          />

          <div class="bluebtns mt-5">
            <b-button variant="primary" class="px-5" @click="startStream()"
              >Okay</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="agentmodal && !endingTest" class="testscreen-custom-modal">
      <div class="testscreen-modal-content-installagent">
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          The agent is still not working. Please do the below steps
        </div>
        <div
          @click="agentCheckForWindows()"
          class="modalcloseicon-camacc pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>

        <div class="mt-2 step-subtext" style="font-size: medium">
          1. Go to windows and search “Hire3x Proctoring Agent”. And allow all
          the permissions to install it again. (If installed already do the
          below step)
        </div>
        <div class="mt-2">
          <img src="../assets/newimgs/Agent/Windows/agentCheck1.png" alt="" />
        </div>

        <div class="mt-2 step-subtext" style="font-size: medium">
          2. The application will appear in the “System tray” if it was
          installed correctly. And right-click on the application in the System
          tray and select Start service.
        </div>
        <div class="mt-2">
          <img src="../assets/newimgs/Agent/Windows/agentCheck2.png" alt="" />
        </div>

        <div
          class="bluebtns d-flex align-items-center justify-content-center mt-3"
        >
          <b-button
            variant="primary"
            class="px-5"
            @click="agentCheckForWindows()"
            >Okay</b-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="notificationmodal"
      class="notification d-flex justify-content-between align-items-center mr-2"
      :class="timeinsecondsfornotificationfunc.style"
    >
      <div style="color: black" class="ml-2">Total Time Remaining:</div>
      <div style="margin-top: 1px">
        {{ testTimer | formatTime }}
      </div>
      <div class="pointer mr-2 close-notify">
        <img
          @click="closeNotification()"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
    </div>
    <Internet />
  </div>
</template>

<script>
import TakeQuestions from "./codingTest/TakeQuestions.vue";
import TryQuestion from "./codingTest/TryQuestion.vue";
import {
  stopTestAgent,
  endTestPost,
  postExtensionLog,
  postMarking,
  posttimer,
  CheckAgentService,
} from "../apiFunction.js";
import Internet from "./fixedcomponents/Internet.vue";
import SocketioService from "../services/socketio.service";
import Spinner from "../components/fixedcomponents/Spinner.vue";
import SectionFilling from "../components/SectionFilling.vue";
import ProgressBar from "../components/ProgressBar.vue";
import { mapGetters } from "vuex";
import { io } from "socket.io-client";
import postMessageUrl from "../mixins/postMessageUrl";
const socket = io("wss://mediaserver.hire3x.com", {
  reconnectionDelayMax: 10000,
});
export default {
  name: "TestScreen",
  mixins: [postMessageUrl],
  components: {
    Spinner,
    SectionFilling,
    ProgressBar,
    TakeQuestions,
    TryQuestion,
    Internet,
  },
  data() {
    return {
      endingTest: false,
      notificationmodal: false,
      timeinsecondsfornotification: null,
      internetDisconnected: false,
      len: 0,
      force: 0,
      laterModalText: "",
      requiredText: "",
      mediaRecorder: null,
      mediaRecorderScreen: null,
      Loaderstatus: false,
      endTestRes: false,
      latermodal: false,
      cantendtest: false,
      cantnavigate: false,
      finishtestmodal: false,
      cameramodal: false,
      agentmodal: false,
      timerEnd: false,
      stream: null,
      screenstream: null,
      timerFix: null,
      items: [],
      socketInterval: null,
      testtimerInterval: null,
      agentInterval: null,
      postMessageinterval: null,
      posttimerinterval: null,
    };
  },
  filters: {
    formatTime(value) {
      console.log(value);
      let dateString = value;
      let dateArray = dateString.split(":");
      let hour = dateArray[0]; // '05'
      let minute = dateArray[1]; // '01'
      let second = dateArray[2]; // '01'
      let finalTime = "";
      if (hour != "00") {
        finalTime = finalTime + hour + " Hour and ";
      }
      if (minute != "00") {
        finalTime = finalTime + minute + " Minute and ";
      }
      if (second != "00") {
        finalTime = finalTime + second + " Second ";
      }
      return finalTime;
    },
  },
  computed: {
    ...mapGetters("codingVUEX", {
      tryQuestionvariable: "gettryQuestion",
      openCodingSection: "getopenCodingSection",
    }),
    ...mapGetters("testVUEX", {
      questionData: "getQuestionData",
      sectionDetails: "getSectionDetails",
      selectedSection: "getSelectedSection",
      screenShareEnabled: "getscreenShareEnabled",
      getallAnswers: "getallAnswer",
      totalanswered: "gettotalanswered",
    }),
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      userID: "getUserID",
      uuid: "getUUID",
      nextBtn: "getNextBtn",
      prevBtn: "getPrevBtn",
      licenseKey: "getlicensekey",
      sectionId: "getsectionId",
      testtimeleft: "gettesttimeleft",
      testtaken: "gettesttaken",
      testTimer: "gettesttimeleftintimeformat",
    }),
    ...mapGetters("questionVUEX", {
      noOfQues: "getNoOfQues",
      lastQuestionIndex: "getLastQuestionIndex",
      noPage: "getNoPage",
      noOfPages: "getNoOfPages",
      pageItem: "getPageItem",
    }),
    timeinsecondsfornotificationfunc() {
      if (this.testDetails.config.includeTimeNotifics) {
        let totaltime = Number(this.testDetails.config.testDuration) * 60;
        if (this.timeinsecondsfornotification <= (5 / 100) * totaltime) {
          return { style: "notify-red-class", imageName: "" };
        } else if (
          this.timeinsecondsfornotification <=
          (25 / 100) * totaltime
        ) {
          return { style: "notify-orange-class", imageName: "" };
        } else if (
          this.timeinsecondsfornotification <=
          (50 / 100) * totaltime
        ) {
          return { style: "notify-yellow-class", imageName: "" };
        } else {
          return { style: "notify-green-class", imageName: "" };
        }
      } else {
        return { style: "", imageName: "whitetimer" };
      }
    },
    notificationmodalalert() {
      if (this.testDetails.config.includeTimeNotifics) {
        let totaltime = Number(this.testDetails.config.testDuration) * 60;
        if (this.timeinsecondsfornotification == (5 / 100) * totaltime) {
          return true;
        } else if (
          this.timeinsecondsfornotification ==
          (25 / 100) * totaltime
        ) {
          return true;
        } else if (
          this.timeinsecondsfornotification ==
          (50 / 100) * totaltime
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  beforeUnmount() {
    SocketioService.disconnect();
  },
  beforeDestroy() {
    clearInterval(this.socketInterval);
    clearInterval(this.testtimerInterval);
    clearInterval(this.agentInterval);
    clearInterval(this.posttimerinterval);
    clearInterval(this.postMessageinterval);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  created() {
    history.pushState(location.href, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
        if(localStorage.getItem("testFinished")){
      this.$router.push({name:"TestClosed",})
    }
    else{
    this.agentInterval = setInterval(() => {
      this.validationForWindowsLinuxMac();
    }, 5000);
    this.$store.commit("testVUEX/storeresponseAvail", true);
    if (this.getallAnswers.length > 0) {
      this.$store.dispatch("testVUEX/saveAnswer", "recall");
    }
    let payload = {
      topicID: this.testDetails.topicID,
      testID: this.testDetails.testID,
      groupID: this.testDetails.groupID,
      userID: this.userID,
      attemptNo: this.testDetails.attemptNo,
    };
    this.$store.dispatch("testVUEX/getTestResponse", payload);
    delete payload.userID;
    this.$store.dispatch("testVUEX/testCount", payload);
    this.timeinsecondsfornotification = localStorage.getItem("timer");
  }
  },
  mounted() {
    if(!localStorage.getItem("testFinished")){

    this.Loaderstatus = true;
    localStorage.setItem("testStarted", true);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    console.log(this.postMessageinterval);
    this.postMessageinterval = setInterval(function() {
      let data = { type: "EXTENSION_ENABLED", text: "EXTENSION_ENABLED" };
      window.postMessage(data, this.url);
    }, 3000);
    window.addEventListener("message", this.windowListenerFunc, false);
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
    document.addEventListener(
      "mozfullscreenchange",
      this.handleFullscreenChange
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.handleFullscreenChange
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.handleFullscreenChange
    );
    SocketioService.setupSocketConnection();
    window.addEventListener("online", function() {
      window.onbeforeunload = null;
      window.location.reload();
    });
    if (this.testDetails.config.enableCamera) {
      this.startStream();
      // Create a MutationObserver to listen for changes in camera permission
      const observer = new MutationObserver(() => {
        this.startStream();
      });

      // Observe the 'state' of camera permission
      navigator.permissions
        .query({ name: "camera" })
        .then((permissionStatus) => {
          permissionStatus.onchange = () => {
            observer.disconnect(); // Disconnect the observer to avoid memory leaks
            this.startStream();
          };
        });
    }
    if (this.testDetails.config.enableScreenShare && !this.screenShareEnabled) {
      this.startscreenshare();
    }

    this.movePage();
    this.Timer();
    this.scrollToBottom();
    this.$store.commit("sectionVUEX/prevnextbtnstatus", this.sectionId);
    this.disableCopy();
    this.$bvModal.show("alertmodal");
    window.addEventListener("offline", () => {
      clearInterval(this.socketInterval);
      clearInterval(this.testtimerInterval);
    });
  }
  },

  watch: {
    getSectionDetails(newValue, oldValue) {
      if (newValue != oldValue) {
        this.sectionDetails = newValue;
      }
    },
    notificationmodalalert() {
      this.notificationmodal = true;
      setTimeout(() => {
        this.notificationmodal = false;
      }, 5000);
    },
  },
  methods: {
    hideLaterModal() {
      this.$bvModal.hide("latermodal");
    },
    closeNotification() {
      this.notificationmodal = false;
    },
    async agentCheckForWindows() {
      try {
        let res = await CheckAgentService(this.testDetails.attemptNo);
        if (res.data.data.AGENT_CHECK_RESPONSE) {
          this.agentmodal = false;
        }
        console.log(res.data.data.AGENT_CHECK_RESPONSE);
      } catch (error) {
        console.log(error);
        this.agentmodal = true;
      }
    },

    validationForWindowsLinuxMac() {
      if (this.testDetails.config.allowAgent) {
        this.agentCheckForWindows();
      }
    },

    preventRightClick(event) {
      event.preventDefault();
    },
    fullScreenMode() {
      const fullscreenElement = document.getElementById("fullscreenElement");
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        // Firefox
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        fullscreenElement.webkitRequestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        // IE/Edge
        fullscreenElement.msRequestFullscreen();
      }
    },
    handleFullscreenChange() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        // We are in full-screen mode
        return;
      } else {
        // We are not in full-screen mode
        this.latermodal = false;
        this.cantendtest = false;
        this.cantnavigate = false;
        this.finishtestmodal = false;
        this.$bvModal.show("alertmodal");
      }
    },
    handleBeforeUnload() {
      this.$store.commit("testVUEX/storeScreenShareEnable", false);
      setTimeout(() => {
        window.onbeforeunload = null;
        window.location.reload();
      }, 3000);
      return "Are you sure you want to leave this window?";
    },
    disableCopy() {
      document.onselectstart = () => false;

      if (window.sidebar) {
        document.onmousedown = this.killCopy;
        document.onclick = this.reEnable;
      }
    },
    enableCopy() {
      document.onselectstart = null;
      document.onmousedown = null;
      document.onclick = null;
    },
    killCopy(e) {
      console.log(e);
      return false;
    },
    reEnable() {
      return true;
    },

    movePage() {
      this.items = this.questionData;
      this.$store.commit(
        "questionVUEX/noOfPages",
        Math.ceil(this.items.length / this.testDetails.config.quesPerScreen)
      );
      for (let i in this.questionData) {
        this.items[i].id = i;
      }
      this.$store.commit("questionVUEX/allItem", this.items);
      this.$store.commit(
        "questionVUEX/noOfQues",
        this.testDetails.config.quesPerScreen
      );
      this.$store.commit(
        "questionVUEX/pageItems",
        this.items.slice(0, this.noOfQues)
      );
      this.len = parseInt(this.lastQuestionIndex) + parseInt(this.noOfQues);
      if (this.Loaderstatus) {
        this.Loaderstatus = false;
      }
      this.$store.commit("questionVUEX/lastQuestionIndex", 0);
    },
    nextPag() {
      this.items = this.questionData;
      this.$store.commit("questionVUEX/saveNoPage", "next");
      this.$store.commit("questionVUEX/pageItems", []);
      let lastIndex = this.noOfQues * this.noPage;
      let firstindex = lastIndex - this.noOfQues;

      this.$store.commit("questionVUEX/lastQuestionIndex", lastIndex);
      this.len = parseInt(this.lastQuestionIndex) + parseInt(this.noOfQues);

      this.$store.commit(
        "questionVUEX/pageItems",
        this.items.slice(firstindex, lastIndex)
      );
    },
    prevPag() {
      this.items = this.questionData;
      this.$store.commit("questionVUEX/saveNoPage", "prev");
      let lastIndex = this.noOfQues * this.noPage;
      let firstindex = lastIndex - this.noOfQues;
      this.$store.commit("questionVUEX/lastQuestionIndex", lastIndex);
      this.len = this.len - parseInt(this.noOfQues);
      this.$store.commit("questionVUEX/pageItems", []);
      this.$store.commit(
        "questionVUEX/pageItems",
        this.items.slice(firstindex, lastIndex)
      );
    },
    nextSection() {
      this.$store.commit("questionVUEX/saveNoPage", "set");
      let selectedSection = this.selectedSection.sectionID;
      this.$store.commit("sectionVUEX/saveTabIndex", "next");
      let index1;
      for (let i in this.sectionDetails) {
        if (this.sectionDetails[i].sectionID === selectedSection) {
          let index = parseInt(i);
          index1 = index + 1;
          this.$store.commit("sectionVUEX/prevnextbtnstatus", index1);
        }
      }
      this.$store.commit(
        "testVUEX/saveSelectedSection",
        this.sectionDetails[index1]
      );
      setTimeout(() => {
        document
          .querySelector(".selected-section-progress")
          .scrollIntoView({ behaviour: "smooth", align: true });
      }, 100);
      this.movePage();
    },
    previous() {
      this.$store.commit("questionVUEX/saveNoPage", "set");
      let selectedSection = this.selectedSection.sectionID;
      this.$store.commit("sectionVUEX/saveTabIndex", "prev");
      let index1;
      for (let i in this.sectionDetails) {
        if (this.sectionDetails[i].sectionID === selectedSection) {
          let index = parseInt(i);
          index1 = index - 1;
          this.$store.commit("sectionVUEX/prevnextbtnstatus", index1);
        }
      }
      this.$store.commit(
        "testVUEX/saveSelectedSection",
        this.sectionDetails[index1]
      );
      setTimeout(() => {
        document
          .querySelector(".selected-section-progress")
          .scrollIntoView({ behaviour: "smooth", align: true });
      }, 100);
      this.movePage();
    },
    scrollToBottom() {
      if (this.selectedSection.sectionName != "programming") {
        this.$nextTick(() => {
          document
            .getElementById(
              "ques" +
                this.pageItem[0].questionKey +
                this.selectedSection.sectionID
            )
            .scrollIntoView({ behaviour: "smooth", align: true });
        });
      }
    },
    closefinishtest() {
      this.finishtestmodal = false;
    },
    finishtestlater() {
      this.force = 1;
      this.stopTestBtn();
    },
    closefinishtestlater() {
      this.latermodal = false;
    },
    async startStream() {
      try {
        if (navigator.getUserMedia) {
          this.cameramodal = false;
          let constraints = {
            audio: false,
            video: {
              mandatory: {
                maxWidth: 1280,
                maxHeight: 720,
              },
            },
          };

          navigator.getUserMedia(
            constraints,
            (stream) => {
              this.stream = stream;
              this.startrecording();
            },
            (err) => {
              this.cameramodal = true;
              console.error(`The following error occurred: ${err.name}`);
            }
          );
        } else {
          this.cameramodal = true;
          console.log("getUserMedia not supported");
        }
      } catch (error) {
        this.cameramodal = true;
        console.log(error);
      }
    },
    async startscreenshare() {
      try {
        let constraints = {
          audio: false,
          video: {
            displaySurface: "monitor",
          },
        };
        this.screenstream = await navigator.mediaDevices.getDisplayMedia(
          constraints
        );
      } catch (e) {
        this.startscreenshare();
      }
      if (
        this.screenstream.getVideoTracks()[0].getSettings().displaySurface ==
        "monitor"
      ) {
        this.screenstream.getVideoTracks()[0].addEventListener("ended", () => {
          this.$store.commit("testVUEX/storeScreenShareEnable", false);
          this.startscreenshare();
        });

        if (this.screenstream.getVideoTracks()[0].readyState == "live") {
          this.$store.commit("testVUEX/storeScreenShareEnable", true);
          this.startrecordingScreenShare();
        }
      } else {
        alert("Please select entire screen in screenshare tab");
        window.location.reload();
      }
    },
    startrecording() {
      let ctestid = this.testDetails.testID;
      let cuserid = this.userID;
      let cgroupid = this.testDetails.groupID;
      let attemptNumber = this.testDetails.attemptNo;
      let stream = this.stream;

      let recordedChunks = [];

      let options = { mimeType: "video/webm; codecs=h264" };
      this.mediaRecorder = new MediaRecorder(stream, options);

      this.mediaRecorder.ondataavailable = handleDataAvailable;
      this.mediaRecorder.start(1000);

      function handleDataAvailable(event) {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
          sendtoserver();
        }
      }
      function sendtoserver() {
        let blob = new Blob(recordedChunks, {
          type: "video/webm",
        });

        let payload = {
          blobs: blob,

          userid: cuserid,
          testid: ctestid,
          attemptNo: attemptNumber,
          groupid: cgroupid,
        };

        socket.emit("message", payload);

        recordedChunks = [];
      }
    },
    startrecordingScreenShare() {
      let ctestid = this.testDetails.testID;
      let cuserid = this.userID;
      let cgroupid = this.testDetails.groupID;
      let attemptNumber = this.testDetails.attemptNo;
      let screenrecordedChunks = [];
      let screenstream = this.screenstream;
      let options = { mimeType: "video/webm; codecs=h264" };
      this.mediaRecorderScreen = new MediaRecorder(screenstream, options);

      this.mediaRecorderScreen.ondataavailable = handleDataAvailablescreen;
      this.mediaRecorderScreen.start(1000);
      function handleDataAvailablescreen(event) {
        if (event.data.size > 0) {
          screenrecordedChunks.push(event.data);
          sendtoserver();
        }
      }
      function sendtoserver() {
        let screenblob = new Blob(screenrecordedChunks, {
          type: "video/webm",
        });

        let payload = {
          screen: screenblob,
          userid: cuserid,
          testid: ctestid,
          attemptNo: attemptNumber,
          groupid: cgroupid,
        };

        socket.emit("messagescreen", payload);
        screenrecordedChunks = [];
      }
    },
    stoprecord() {
      let ctestid = this.testDetails.testID;
      let cuserid = this.userID;
      let attemptNumber = this.testDetails.attemptNo;
      let cgroupid = this.testDetails.groupID;

      let types = "";
      let proctoring = false;
      if (this.testDetails.config.enableProctoring) {
        proctoring = true;
      }
      if (
        this.testDetails.config.enableCamera &&
        !this.testDetails.config.enableScreenShare
      ) {
        types = "camera";
      } else if (
        this.testDetails.config.enableScreenShare &&
        !this.testDetails.config.enableCamera
      ) {
        types = "screen";
      } else {
        types = "both";
      }

      let payload = {
        userid: cuserid,
        testid: ctestid,
        groupid: cgroupid,
        attemptNo: attemptNumber,
        env_type: 1,
        licenseKey: this.licenseKey,
        time: this.testDetails.config.testDuration,
        proctor: proctoring,
        type: types,
      };
      socket.emit("forceDisconnect", payload);
    },

    async windowListenerFunc(event) {
      if (event.source != window) {
        return;
      }
      if (event.data.type == "ACTIVITY_LOG") {
        let check =
          parseInt(this.testDetails.config.testDuration * 60) -
          parseInt(this.timerFix);
        event.data.data.timeStampInVideo = this.convertSecondsToHHMMSS(check);
        let extensionPayload = {
          testID: this.testDetails.testID,
          topicID: this.testDetails.topicID,
          groupID: this.testDetails.groupID,
          userID: this.userID,
          licenseKey: this.licenseKey,
          activity: event.data.data,
          attemptNo: this.testDetails.attemptNo,
        };
        postExtensionLog(extensionPayload);
      }
      if (event.data.type == "EXTENTION_IS_CONFIGRED") {
        let status = {
          type: "EXTENTION_IS_CONFIGRED_RESPONSE",
          permission: this.testDetails.config.allowExtension,
        };
        window.postMessage(status, this.url);
      }
      if (event.data.type == "NEW_TAB") {
        if (event.data.data) {
          this.$bvModal.show("cantopentab");
        }
      }
    },
    finishTestStopt() {
      if (this.testTimer == "00:00:01") {
        this.force = 1;
        this.timerEnd = true;
      }
      this.timerEnd = false;
      this.stopTestBtn();
    },
    stopTestBtn() {
      window.onbeforeunload = null;
      this.endTestPost();
      this.stoprecord();

      setTimeout(() => {
        if (this.endTestRes) {
          if (this.testDetails.config.allowAgent) {
            this.StopAgent();
          }
          SocketioService.socket.emit("endTest", {
            userID: this.userID,
            testID: this.testID,
          });
          let data = { type: "STOP_TEST", text: "STOP_TEST" };
          window.postMessage(data, this.url);
        }
      }, 1000);
    },
    async endTestPost() {
      try {
        this.endingTest = true;
        this.Loaderstatus = true;
        if (this.timerEnd) {
          this.force = 1;
        }
        let data = {
          topicID: this.testDetails.topicID,
          testID: this.testDetails.testID,
          groupID: this.testDetails.groupID,
          timerEnd: this.timerEnd,
          force: this.force,
          attemptNo: this.testDetails.attemptNo,
        };
        console.log(data, "endtestpost payload 1");
        const res = await endTestPost(data);
        if (res.status == 200) {
          this.endTestRes = true;
          let payload = {};
          payload.topicID = this.testDetails.topicID;
          payload.testID = this.testDetails.testID;
          payload.groupID = this.testDetails.groupID;
          payload.candidateID = this.userID;
          payload.groupID = this.testDetails.groupID;
          payload.attemptNo = this.testDetails.attemptNo;
          const res1 = await postMarking(payload);
          this.finishTest();
          console.log(res1);
        } else if (res.status == 409) {
          this.requiredText = res.data.data.Detail;
          this.endTestRes = false;
          this.cantendtest = true;
        } else if (res.status == 406) {
          this.laterModalText = res.data.data.Detail;
          this.latermodal = true;
        }
      } catch (error) {
        this.Loaderstatus = false;
        if (error.response.status == 409) {
          this.requiredText = error.response.data.data.Detail;
          this.endTestRes = false;
          this.cantendtest = true;
        } else if (error.response.status == 406) {
          this.laterModalText = error.response.data.data.Detail;
          this.latermodal = true;
        } else {
          this.endTestRes = true;
        }
      } finally {
        this.endingTest = false;
      }
    },
    StopAgent() {
      stopTestAgent();
    },
    convertSecondsToHHMMSS(seconds) {
      const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
      const minutes = Math.floor((seconds % 3600) / 60); // 60 seconds in a minute
      const remainingSeconds = seconds % 60;

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    Timer() {
      let timeset = Number(this.testDetails.config.testDuration) * 60;
      let timer = localStorage.getItem("timer");
      this.$store.commit("sectionVUEX/saveremaininigtesttime", timer);
      this.$store.commit("sectionVUEX/testtaken", true);
      SocketioService.socket.emit("message", "start");
      SocketioService.socket.emit("sendtime", {
        timer: timer,
        userID: this.userID,
        testID: this.testDetails.testID,
        duration: timeset,
        attemptNo: this.testDetails.attemptNo,
      });
      let last = new Date().getTime();
      this.socketInterval = setInterval(() => {
        let current = new Date().getTime();
        if (current - last > 15000) {
          console.log("reload");
        }
        last = current;

        this.$store.commit(
          "sectionVUEX/saveremaininigtesttime",
          this.testtimeleft
        );
        let time =
          ("0" + (Math.floor(this.testtimeleft / 3600) % 24)).slice(-2) +
          ":" +
          ("0" + (Math.floor(this.testtimeleft / 60) % 60)).slice(-2) +
          ":" +
          ("0" + (this.testtimeleft % 60)).slice(-2);
        let msg = time;
        localStorage.setItem("remainingtime", msg);
        let remainingtime = localStorage.getItem("remainingtime");
        this.$store.commit(
          "sectionVUEX/saveremaininigtesttimeintimeformat",
          remainingtime
        );
        let array = remainingtime.split(":");
        let seconds =
          parseInt(array[0], 10) * 3600 +
          parseInt(array[1], 10) * 60 +
          parseInt(array[2], 10);
        localStorage.setItem("timer", seconds);
        this.timerFix = seconds;
        this.timeinsecondsfornotification = seconds;
        if (seconds <= 0) {
          clearInterval(this.socketInterval);
          clearInterval(this.testtimerInterval);
          clearInterval(this.agentInterval);
        }
      }, 1000);

      this.testtimerInterval = setInterval(() => {
        const closeTestdt = new Date(this.testDetails.config.closeTestdt);
        const current = new Date();
        const buffer = new Date(closeTestdt.getTime() + 15 * 60000);
        if (
          this.testTimer == "00:00:00" ||
          this.testTimer == "00:00:01" ||
          current > buffer
        ) {
          this.$store.commit(
            "sectionVUEX/saveremaininigtesttimeintimeformat",
            "00:00:00"
          );
          this.timerEnd = true;
          this.force = 1;
          this.stopTestBtn();
          clearInterval(this.socketInterval);
          clearInterval(this.testtimerInterval);
          clearInterval(this.agentInterval);
        }
      }, 1000);
      this.posttimerinterval = setInterval(() => {
        let timer = localStorage.getItem("timer");
        let payload = {
          testID: this.testDetails.testID,
          timer: parseInt(timer),
          groupID: this.testDetails.groupID,
          attemptNo: this.testDetails.attemptNo,
        };
        this.posttimereveryminute(payload);
      }, 60000);
    },
    async posttimereveryminute(data) {
      await posttimer(data);
    },
    closechatbotcard() {
      this.chatbotclicked = false;
    },
    chatbotcarddisplay() {
      this.chatbotclicked = true;
    },

    finishTest() {
      window.removeEventListener("beforeunload", this.handleBeforeUnload);
      window.onbeforeunload = null;
      this.agentmodal = false;
      clearInterval(this.agentInterval);
      localStorage.removeItem("testStarted");
      localStorage.removeItem("proctertimer");
      localStorage.setItem("testFinished", true);
      if (this.screenstream) {
        const tracks = this.screenstream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      if (this.stream) {
        const tracks = this.stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      document.removeEventListener(
        "fullscreenchange",
        this.handleFullscreenChange
      );
      this.$router.push(
        {
          path: "/finishtest",
        },
        () => {
          this.$router.go(0);
        }
      );
      // }
      this.Loaderstatus = false;
    },
  },
};
</script>

<style scoped>
.close-notify {
  background: white;
  border-radius: 50px;
}
.notification {
  position: fixed;
  top: 10px;
  right: 120px;
  border-radius: 5px;
  width: 400px;
  height: 35px;
  background: #4c78ce;
  text-align: center;
}
.notify-green-class {
  background-color: #269C32;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}
.notify-red-class {
  background-color: #EB0002;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}
.notify-yellow-class {
  background-color: yellow;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}
.notify-orange-class {
  background-color: #FFC145;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}
@media only screen and (max-width: 820px) {
  .screenSizeLap {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}

video {
  height: 15%;
  width: 15%;
  visibility: hidden;
  position: absolute;
  top: 1%;
}
.testscreen-custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background overlay */
  z-index: 1500;
}

.testscreen-modal-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 14px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
  padding: 10px;
  position: relative;
  width: 470px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-title {
  margin: 0;
}

.close {
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* internet disconnect design */
.internet-disconnected-modal-content {
  border-radius: 18.743px;
  background: #fff;
  box-shadow: 0px 20.08215px 40.1643px 0px rgba(122, 122, 122, 0.2);
  width: 599.303px;
  height: fit-content;
  overflow: auto;
  padding: 12px;
  position: relative;
}

.pagination-icon .btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.pagination-icon .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  box-shadow: none !important;
}

.testscreen-modal-content-camacc {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 14px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* max-width: 50%; */
  /* max-height: 80%; */
  /* overflow: auto; */
  padding: 10px;
  position: relative;
  width: 650px;
}
.modalcloseicon-camacc {
  position: absolute;
  top: 12px;
  right: 15px;
}
.testscreen-modal-content-installagent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 14px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 56%;
  /* max-height: 80%; */
  /* overflow: auto; */
  padding: 28px;
  position: relative;
  /* width: 650px; */
}
</style>
