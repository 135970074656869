var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"textcolors"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"textcolor-activecolor"}),_c('div',{staticClass:"textcolor-text ml-2"},[_vm._v("Required")])])]),_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"textcolor-attemptedcolor"}),_c('div',{staticClass:"textcolor-text ml-2"},[_vm._v("Attempted")])])])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"textcolor-mflcolor"}),_c('div',{staticClass:"textcolor-text ml-2"},[_vm._v("Mark for later")])])]),_c('b-col',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"textcolor-unattemptedcolor"}),_c('div',{staticClass:"textcolor-text ml-2"},[_vm._v("Unattempted")])])])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"infodetails mt-2"},[_c('img',{attrs:{"src":require("../assets/infoicon.svg"),"alt":""}}),_vm._v(" To go to particular question, click on the question number below ")])])],1)],1),_c('div',{staticClass:"right-line mt-2"}),_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('div',{staticClass:"pgbar-outerdiv pb-2 w-100"},_vm._l((_vm.sectionDetails),function(section,secindex){return _c('div',{key:section.sectionID,staticClass:" pointer m-3 p-2",class:section.sectionID == _vm.selectedSection.sectionID
            ? 'selected-section-progress'
            : 'pgbar-section',attrs:{"id":section.sectionID},on:{"click":function($event){return _vm.selectSection(section, 0, secindex)}}},[(section.codingSection == false)?[_c('div',{staticClass:"ml-2 pgbar-secname text-truncate"},[_c('span',[_vm._v(_vm._s(section.sectionName))])]),_c('div',{staticClass:"d-flex flex-wrap ml-1"},_vm._l((section.sectionResponse),function(item,index){return _c('div',{key:item['questionKey']},[_c('div',{staticClass:"pointer d-flex align-items-center justify-content-center",class:item.answerStatus.later
                    ? 'ques-blocks-later'
                    : item.answerStatus.attempted
                    ? 'ques-blocks-attempted'
                    : item.questionBody.config.config.required
                    ? 'ques-blocks-active'
                    : 'ques-blocks-unattempted',staticStyle:{"width":"35px"},on:{"click":function($event){$event.stopPropagation();return _vm.select(section, item.questionKey, index, secindex)}}},[_vm._v(" Q"+_vm._s(index + 1)+" ")])])}),0)]:_vm._e(),(section.codingSection == true)?[_c('div',[_c('div',{staticClass:"ml-2 pgbar-secname text-truncate"},[_c('span',[_vm._v(_vm._s(section.sectionName))])]),_c('div',_vm._l((section.sectionResponse),function(items,sectionindex){return _c('div',{key:sectionindex,staticClass:"showCodingQuestions pgbar-section d-flex flex-wrap",class:_vm.selectedCodingSectionIndex == sectionindex
                    ? 'selected-section-progress'
                    : '',on:{"click":function($event){$event.stopPropagation();return _vm.selectcodingquestion(sectionindex, section)}}},[_c('div',{staticClass:"w-100 cq-black-txt"},[_vm._v("Section "+_vm._s(sectionindex+1))]),_vm._l((items.sectionResponse),function(item,index){return _c('div',{key:item['questionKey']},[_c('div',{staticClass:"pointer d-flex align-items-center justify-content-center",class:item.answerStatus.later
                        ? 'ques-blocks-later'
                        : item.answerStatus.attempted
                        ? 'ques-blocks-attempted'
                        : item.questionBody.config.config.required
                        ? 'ques-blocks-active'
                        : 'ques-blocks-unattempted',staticStyle:{"width":"35px"},on:{"click":function($event){$event.stopPropagation();return _vm.openCodingQuetionFromTestScreen(
                        item,
                        sectionindex,
                        index,
                        secindex,section
                      )}}},[_vm._v(" Q"+_vm._s(index + 1)+" ")])])})],2)}),0)])]:_vm._e()],2)}),0)]),_c('div',{staticClass:"test-right-bottom-container"},[_c('div',{staticClass:"relativeclass"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"testrbc-text"},[_vm._v(_vm._s(_vm.testDetails.name))]),_c('div',{staticClass:"testrbc-number"},[_vm._v(" "+_vm._s(_vm.totalanswered.attemptedQuestions)+"/"+_vm._s(_vm.totalanswered.totalQuestions)+" ")])]),_c('div',{staticClass:"test-progressbar"},[_c('b-progress',{staticClass:"w-100",attrs:{"max":_vm.totalanswered.totalQuestions,"value":_vm.totalanswered.attemptedQuestions,"height":"10px"}})],1),_c('div',{staticClass:"test-progress-foot mt-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"test-right-footer-progress mt-2 relativeclass",staticStyle:{"width":"100%"}},[_c('b-progress',{staticClass:"mb-2 w-100",attrs:{"value":_vm.selectedSection.sectionName != 'programming'
                  ? _vm.selectedSection.attemptedCount
                  : _vm.lengthOfprogarmmingQuetionsattempt,"max":_vm.selectedSection.sectionName != 'programming'
                  ? _vm.selectedSection.sectionResponse.length
                  : _vm.lengthOfprogarmmingQuetions}}),_c('div',{staticClass:"test-progressbar-text"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.selectedSection.sectionName),expression:"selectedSection.sectionName",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm._f("sectionname")(_vm.selectedSection.sectionName)))]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.selectedSection.sectionName != "programming" ? _vm.selectedSection.attemptedCount : _vm.lengthOfprogarmmingQuetionsattempt)+"/"+_vm._s(_vm.selectedSection.sectionName != "programming" ? _vm.selectedSection.sectionResponse.length : _vm.lengthOfprogarmmingQuetions)+" ")])])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }