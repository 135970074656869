<template>
  <div class="h-100">
    <div v-if="Loaderstatus" class="relativeclass">
      <div class="testscreen-spinnerstyle">
        <Spinner />
      </div>
    </div>
    <div class="d-flex h-100">
      <div class="cq-left-container mr-0 h-100">
        <div class="cq-top-div d-flex align-items-center">
          <img
            @click="gobackTohome()"
            class="mr-3 pointer"
            src="../../assets/newui-img/gobck.svg"
            alt=""
          />
          <b class="mr-2">Section {{ storeQuetionsForcodingall+1 }} :</b
          >{{ currentCodingSectionAll.sectionName }}
        </div>
        <div class="cq-bottom-div">
          <div
            v-for="(data, i) in currentCodingSection"
            :key="i"
            class="cq-ques-card d-flex mt-2"
          >
            <div
              class="my-2"
              :class="
                data.answerStatus.attempted == true
                  ? 'cq-green-border'
                  : 'cq-red-border'
              "
            ></div>
            <div class="p-2 ml-1 w-100">
              <div class="d-flex ">
                <div class="d-flex" style="width: 85%;">
                  <div class="cq-ques-no pr-2">Q{{ i + 1 }}.</div>
                  <div
                    class="cq-ques-no text-break"
                    style="min-width: auto;"
                    :style="
                      testDetails.config.dispMarks
                        ? 'max-width:60%'
                        : 'max-width:66%'
                    "
                  >
                    {{ data.questionBody.title }}
                  </div>

                  <div class="d-flex pr-2">
                    <div
                      v-if="testDetails.config.dispMarks"
                      class="cq-inside-box ml-2"
                      style="height: 27px"
                    >
                      {{ data.questionBody.marks }} Mark{{
                        data.questionBody.marks > 1 ? "s" : ""
                      }}
                    </div>
                    <div class="cq-inside-box ml-2" style="height: 27px;">
                      Difficulty Level-&nbsp;{{ difficultyLevelText(data) }}
                    </div>
                  </div>
                </div>

                <div
                  class="markforlatercheckbox dodontcheckbox d-flex justify-content-end"
                  style="width: 15%;"
                >
                  <b-form-checkbox
                    name="checkbox-1"
                    v-model="data.answerStatus.later"
                    @change="markforLater(data)"
                    style="width: 105px;"
                  >
                    Mark for later
                  </b-form-checkbox>
                </div>
              </div>
              <div
                class="cq-ques-text mt-3"
                v-html-safe="data.questionBody.question"
              ></div>
              <!-- <div class="sec-ques-style mt-3">
                <span class="testdetails-simpledetails-headtxt">Tags :</span>
                {{ data.questionBody.questionTags.join(", ") }}
              </div> -->
              <div class="d-flex align-items-end justify-content-end">
                <!-- <div class="mt-3 d-flex">
                  <div class="cq-inside-box mr-4">
                    Difficulty Level- {{ data.questionBody.difficultyLevel }}
                  </div> -->
                <!-- <div class="cq-inside-box">
                    {{ data.questionBody.marks }} Mark
                  </div> -->
                <!-- </div> -->
                <div
                  class="cq-tryQuestionBtn pointer"
                  @click="oneFunc(data, i)"
                >
                  Solve
                  <img src="../../assets/newui-img/proceed.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "../fixedcomponents/Spinner.vue";
import { getTestResponse } from "../../apiFunction";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      Loaderstatus: false,
      transformedDatawithcoding: [],
      currentCodingSection: [],
      currentCodingSectionAll: {},
    };
  },
  components: {
    Spinner,
  },
  computed: {
    ...mapGetters("testVUEX", {
      storeQuetionsForcodingall: "getstoreQuetionsForcodingall",
      selectedSection: "getSelectedSection",
    }),
    ...mapGetters("sectionVUEX", {
      userID: "getUserID",
      testDetails: "getTestDetails",
    }),
  },
  watch: {
    storeQuetionsForcodingall() {
      this.getAllResponse();
    },
  },
  created() {
    this.getAllResponse();
  },
  methods: {
    difficultyLevelText(data) {
      if (
        data.questionBody.difficultyLevel === 1 ||
        data.questionBody.difficultyLevel === 2
      ) {
        return "Easy";
      } else if (
        data.questionBody.difficultyLevel === 3 ||
        data.questionBody.difficultyLevel === 4
      ) {
        return "Medium";
      } else return "Hard";
    },
    oneFunc(data, i) {
      this.$store.commit("codingVUEX/storemoveBtn", i);
      this.$store.commit("codingVUEX/datafortryquestion", data);
      this.$store.commit("codingVUEX/savetryQuestion", true);
    },
    gobackTohome() {
      this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", null);
      this.$store.commit("codingVUEX/openCodingSection", false);
    },
    markforLater(data) {
      console.log(data);
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.currentCodingSectionAll.sectionID,
        questionKey: data.questionKey,
        answer: data.answer,
        answerStatus: data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      console.log(payload);
      console.log(this.selectedSection.sectionID);
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      // this.getAllResponse()
      this.$store.commit("testVUEX/updateCodingSectionDetails", {
        payload: payload,
        sectionID: this.currentCodingSectionAll.sectionID,
      });
    },
    async getAllResponse() {
      this.Loaderstatus = true;
      let payloads = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        userID: this.userID,
        attemptNo: this.testDetails.attemptNo,
      };
      const res = await getTestResponse(
        payloads.topicID,
        payloads.testID,
        payloads.groupID,
        payloads.userID,
        payloads.attemptNo
      );
      this.transformedDatawithcoding = res.data.data.testResponse
        .filter((item) => item.codingSection)
        .map((item) => {
          return {
            ...item,
            attemptedCount: 0,
          };
        });
      this.currentCodingSectionAll = this.transformedDatawithcoding[
        this.storeQuetionsForcodingall
      ];
      this.currentCodingSection = this.transformedDatawithcoding[
        this.storeQuetionsForcodingall
      ].sectionResponse;
      this.Loaderstatus = false;
    },
  },
};
</script>
<style scoped>
.not-allowed {
  cursor: not-allowed;
}
.cq-left-container {
  width: 100%;
  border-radius: 5px;
  height: calc(100vh - 80px);
  margin-right: 10px;
}

.cq-top-div {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(196, 196, 196, 0.5);
  border-radius: 5px;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.cq-bottom-div {
  padding: 15px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.cq-ques-card {
  border-radius: 14px;
  background: #f3f7fe;
  padding: 4px;
}

.cq-green-border {
  background-color: #4ef085;
  border-radius: 4px;
  width: 4px;
}

.cq-red-border {
  background-color: #ff4752;
  border-radius: 4px;
  width: 4px;
}

.w-80 {
  width: 80%;
}

.cq-ques-no {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #20639b;
}
.cq-ques-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.cq-tag {
  font-weight: 400;
  font-size: 13px;
  color: #696969;
}
.cq-inside-box {
  background: #ffffff;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: #20639b;
  padding: 5px 12px;
  white-space: nowrap;
}
.cq-tryQuestionBtn {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #20639b;
}
</style>
