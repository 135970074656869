<template>
  <div>
    <div
      class="quescard d-flex relativeclass"
      :id="'ques' + data.questionKey + selectedSection.sectionID"
    >
      <div
        class="quescard-colorbar ml-2"
        :class="
          data.answerStatus.later
            ? 'side-blocks-later'
            : data.answerStatus.attempted
            ? 'side-blocks-attempted'
            : data.questionBody.config.config.required
            ? 'side-blocks-active'
            : 'side-blocks-unattempted'
        "
      ></div>
      <div class="d-flex flex-column mcqcard">
        <b-row class="mb-2">
          <b-col>
            <div class="d-flex align-items-center">
              <div class="quescard-mainhead">Match the Following</div>
              <div class="marktagbox ml-2" v-if="testDetails.config.dispMarks">
                {{ data.questionBody.marks }} Marks
              </div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <template v-if="testDetails.config.dispReqStatus">
        <div
          class="reqd-txt mr-3"
          v-if="data.questionBody.config.config.required"
        >
          <img
            src="../../assets/newimgs/testscreen/reqQuesIcon.svg"
            alt=""
            class=""
          />
          Required
        </div>
      </template>
            <div
              class="
              markforlatercheckbox 
              dodontcheckbox
                d-flex
                align-items-center
                justify-content-end
              "
            >
              <b-form-checkbox
                name="checkbox-1"
                v-model="data.answerStatus.later"
                @change="markforLater()"
              >
                Mark for later
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex flex-column">
          <div class="d-flex">
            <div class="sec-ques-style ">Q{{ showindex }}.</div>
            <div
              class="sec-ques-style ml-1 topicDescBreak"
              v-html-safe="data.questionBody.question"
            ></div>
          </div>
          <div v-if="data.questionBody.questionMedia != null" class="mb-3">
            <div
              v-if="isImage(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <img
                class="qb-image-preview"
                :src="data.questionBody.questionMedia"
                alt=""
              />
            </div>
            <div
              v-if="isAudio(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <Media
                :kind="'audio'"
                :isMuted="false"
                :src="data.questionBody.questionMedia"
                :autoplay="false"
                :controls="true"
                :loop="true"
                class="mcqdatacard-audio"
              ></Media>
            </div>
            <div
              v-if="isVideo(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <Media
                :kind="'video'"
                :isMuted="false"
                :src="data.questionBody.questionMedia"
                :autoplay="false"
                :controls="true"
                :loop="true"
                class="qb-image-preview"
              ></Media>
            </div>
          </div>
        </div>
        <div class="relativeclass mt-2">
          <div class="mtfnotetext">
            <span class="asterik">*</span>Drag and match the answers
          </div>
          <b-row class="p-2">
            <b-col class="pr-0">
              <div
                v-for="(option, index) of data.questionBody.answerKey"
                ref="sideARefs"
                :key="index"
              >
                <div                 :style="{ height: heights[index] + 'px' }"
                class="mafo-option-text mt-2 paired-item text-break">{{ option.sideA }}</div>
              </div>
            </b-col>
            <b-col class="pl-0">
              <draggable
                v-model="data.answer"
                :move="handleMove"
                @end="handleDragEnd"
              >
                <transition-group tag="div" class="grid" name="grid">
                  <div
                    class="cell mafo-option-text mt-2 pointer d-flex  paired-item text-break"
                     :style="{ height: heights[index] + 'px' }"
                    style="border-radius: 0px 5px 5px 0px;"
                       ref="answerRefs"
                    v-for="(item, index) in data.answer"
                    :key="index"
                  >
                    <div
                      ><img
                        src="../../assets/icons/testscreen/sixdots.svg"
                        alt=""
                    /></div>
                    <div class="ml-2 mafo-matching-answer ">{{ item }}</div>
                  </div>
                </transition-group>
              </draggable>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'

import { isImage, isVideo, isAudio } from "../../checkFileType.js";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "mtfAnswercard",
  components: {
    // Media,
    draggable,
    // Spinner,
  },

  props: {
    data: Object,
    showindex: Number,
  },
  data() {
    return {
      showMarks: null,
      showReqStatus: null,
      markLater: false,
      drag: false,
      // root : [1,2,3],
      active: false,
      rad_option: -1,
      option: "Match The Following",
      items: [],
      Loaderstatus: false,
      futureIndex: 0,
      movingIndex: 0,
      heights: []
    };
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
    ...mapGetters("testVUEX", {
      selectedSection: "getSelectedSection",
    }),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  mounted() {
    this.syncHeights(); 
  },

  methods: {
    isImage,
    isVideo,
    isAudio,
    handleDragEnd() {
      this.futureItem = this.data.answer[this.futureIndex];
      this.movingItem = this.data.answer[this.movingIndex];
      const _items = Object.assign([], this.data.answer);
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;
      this.data.answer = _items;
      this.data.answerStatus.unattempted = false;
      this.data.answerStatus.attempted = true;
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.data.questionKey,
        answer: this.data.answer,
        answerStatus: this.data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
           this.syncHeights();
           this.$emit('changeOption');

    },

    syncHeights() {
        this.$nextTick(() => {
          const sideAHeights = this.$refs.sideARefs.map(el => el.clientHeight);
          const answerHeights = this.$refs.answerRefs.map(el => el ? el.clientHeight : 0);

          this.heights = sideAHeights.map((sideAHeight, index) => {
            const answerHeight = answerHeights[index] || 0;
            return Math.max(sideAHeight, answerHeight);
          });

        });
    },
    handleMove(e) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      return false; // disable sort
    },
    markforLater() {
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.data.questionKey,
        answer: this.data.answer,
        answerStatus: this.data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      console.log(payload);
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
  },
};
</script>
<style>
.mafo-matching-answer{
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #3a3c40;
}
.paired-item {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  min-height: 100%; /* Ensure items stretch to fill available height */
  box-sizing: border-box;
  padding: 10px; /* Add padding if needed */
}

/* Apply equal height to each item */
.mafo-option-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;

}
</style>