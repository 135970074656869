<template>
  <div>
    <MobileTestScreen v-if="isLaptop == 'mobile'" />
    <LaptopTestScreen  v-if="isLaptop == 'laptop'" />
  </div>
</template>

<script>
import LaptopTestScreen from "../components/TestScreen.vue";
import MobileTestScreen from "../components/mobileView/MobileTestScreen.vue";
export default {
  name: "TestDetails",
  data() {
    return {
      isLaptop: "",
    };
  },
  components: {
    LaptopTestScreen,
    MobileTestScreen,
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize); // Add event listener for window resize
    this.checkScreenSize(); // Check screen size initially
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Remove event listener before component unmounts
  },
  methods: {
    checkScreenSize() {
     let isLaptopcheck = window.innerWidth >= 1024;
      if(isLaptopcheck){
        this.isLaptop = 'laptop';
      }
      if(!isLaptopcheck){
        this.isLaptop = 'mobile';
      }
    },
  },
};
</script>
