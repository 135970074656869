<template>
  <div>
    <div class="textcolors">
      <b-row>
        <b-col>
          <div class="d-flex align-items-center">
            <div class="textcolor-activecolor"></div>
            <div class="textcolor-text ml-2">Required</div>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center">
            <div class="textcolor-attemptedcolor"></div>
            <div class="textcolor-text ml-2">Attempted</div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div class="d-flex align-items-center">
            <div class="textcolor-mflcolor"></div>
            <div class="textcolor-text ml-2">Mark for later</div>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center">
            <div class="textcolor-unattemptedcolor"></div>
            <div class="textcolor-text ml-2">Unattempted</div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="infodetails mt-2">
            <img src="../assets/infoicon.svg" alt="" />
            To go to particular question, click on the question number below
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="right-line mt-2"></div>
    <div class="d-flex align-items-center flex-wrap">
      <div class="pgbar-outerdiv pb-2 w-100">
        <div
          v-for="(section, secindex) in sectionDetails"
          :key="section.sectionID"
          :id="section.sectionID"
          class=" pointer m-3 p-2"
          :class="
            section.sectionID == selectedSection.sectionID
              ? 'selected-section-progress'
              : 'pgbar-section'
          "
          @click="selectSection(section, 0, secindex)"
        >
          <template v-if="section.codingSection == false">
            <div class="ml-2 pgbar-secname text-truncate">
              <span>{{ section.sectionName }}</span>
            </div>
            <div class="d-flex flex-wrap ml-1">
              <div
                v-for="(item, index) in section.sectionResponse"
                :key="item['questionKey']"
              >
                <!-- {{item}} -->
                <div
                  style="width: 35px"
                  :class="
                    item.answerStatus.later
                      ? 'ques-blocks-later'
                      : item.answerStatus.attempted
                      ? 'ques-blocks-attempted'
                      : item.questionBody.config.config.required
                      ? 'ques-blocks-active'
                      : 'ques-blocks-unattempted'
                  "
                  class="pointer d-flex align-items-center justify-content-center"
                  @click.stop="
                    select(section, item.questionKey, index, secindex)
                  "
                >
                  Q{{ index + 1 }}
                </div>
              </div>
            </div>
          </template>
          <template v-if="section.codingSection == true">
            <div>
              <div class="ml-2 pgbar-secname text-truncate">
                <span>{{ section.sectionName }}</span>
              </div>
              <div>
                
                <div
                  v-for="(items, sectionindex) in section.sectionResponse"
                  :key="sectionindex"
                  class="showCodingQuestions pgbar-section d-flex flex-wrap"
                  @click.stop="selectcodingquestion(sectionindex, section)"
                  :class="
                    selectedCodingSectionIndex == sectionindex
                      ? 'selected-section-progress'
                      : ''
                  "
                >
                <div class="w-100 cq-black-txt">Section {{ sectionindex+1 }}</div>
                  <div
                    v-for="(item, index) in items.sectionResponse"
                    :key="item['questionKey']"
                  >
                    <!-- {{item}} -->
                    <div
                      style="width: 35px"
                      :class="
                        item.answerStatus.later
                          ? 'ques-blocks-later'
                          : item.answerStatus.attempted
                          ? 'ques-blocks-attempted'
                          : item.questionBody.config.config.required
                          ? 'ques-blocks-active'
                          : 'ques-blocks-unattempted'
                      "
                      class="pointer d-flex align-items-center justify-content-center"
                      @click.stop="
                        openCodingQuetionFromTestScreen(
                          item,
                          sectionindex,
                          index,
                          secindex,section
                        )
                      "
                    >
                      Q{{ index + 1 }}
                    </div>
                  </div>
                  <!-- {{item}} -->

                  <!-- {{item.sectionResponse}} -->
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="test-right-bottom-container">
      <div class="relativeclass">
        <div class="d-flex justify-content-between">
          <div class="testrbc-text">{{ testDetails.name }}</div>
          <div class="testrbc-number">
            {{ totalanswered.attemptedQuestions }}/{{
              totalanswered.totalQuestions
            }}
          </div>
        </div>
        <div class="test-progressbar">
          <b-progress
            :max="totalanswered.totalQuestions"
            :value="totalanswered.attemptedQuestions"
            height="10px"
            class="w-100"
          ></b-progress>
        </div>
        <div class="test-progress-foot mt-2">
          <div class="d-flex align-items-center">
            <div
              class="test-right-footer-progress mt-2 relativeclass"
              style="width: 100%"
            >
           
              <b-progress
                :value="
                  selectedSection.sectionName != 'programming'
                    ? selectedSection.attemptedCount
                    : lengthOfprogarmmingQuetionsattempt
                "
                :max="
                  selectedSection.sectionName != 'programming'
                    ? selectedSection.sectionResponse.length
                    : lengthOfprogarmmingQuetions
                "
                class="mb-2 w-100"
              ></b-progress>
              <div class="test-progressbar-text">
                <span v-b-popover.hover.top="selectedSection.sectionName">{{
                  selectedSection.sectionName | sectionname
                }}</span>
                <span style="font-weight: 400;">
                  {{
                    selectedSection.sectionName != "programming"
                      ? selectedSection.attemptedCount
                      : lengthOfprogarmmingQuetionsattempt
                  }}/{{
                    selectedSection.sectionName != "programming"
                      ? selectedSection.sectionResponse.length
                      : lengthOfprogarmmingQuetions
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getTestResponse } from "../apiFunction";
export default {
  name: "ProgressBar",
  components: {
    // Spinner,
    // "progress-button": Button,
  },
  data() {
    return {
      index: 15,
      transformedDatawithcoding:[],
      testvalue: 75,
      testmax: 100,
      count: 0,
      value: 0,
      max: 0,
      attemptedCount: 0,
      isLoading: false,
    };
  },

  computed: {
    lengthOfprogarmmingQuetions() {
      let len = [];
      if (this.selectedSection.sectionName == "programming") {
        this.selectedSection.sectionResponse.map((data) => {
          data.sectionResponse.map((datas) => {
            len.push(datas);
          });
        });
      }
      return len.length;
    },
    lengthOfprogarmmingQuetionsattempt() {
      let len = 0;
      if (this.selectedSection.sectionName == "programming") {
        this.transformedDatawithcoding.map((data) => {
          data.sectionResponse.map((datas) => {
            if (datas.answerStatus.attempted) {
              len = len + 1;
            }
          });
        });
      }
      return len;
    },
    ...mapGetters("testVUEX", {
      sectionDetails: "getSectionDetails",
      selectedSection: "getSelectedSection",
      questionData: "getQuestionData",
      totalanswered: "gettotalanswered",
    }),
    ...mapGetters("questionVUEX", {
      allItem: "getallItem",
      pageItem: "getPageItem",
      answerData: "getAnswerData",
      noOfPages: "getNoOfPages",
      noOfQues: "getNoOfQues",
      lastQuestionIndex: "getLastQuestionIndex",
    }),
    ...mapGetters("sectionVUEX", {
      selectedCodingSectionIndex: "getSelectedCodingSectionIndex",
      testDetails: "getTestDetails",
      userID: "getUserID",
    }),
  },

  created() {
    setTimeout(() => {
      let payload = {};
      payload.testID = this.testDetails.testID;
      payload.topicID = this.testDetails.topicID;
      payload.groupID = this.testDetails.groupID;
      payload.attemptNo = this.testDetails.attemptNo;
      this.$store.dispatch("testVUEX/testCount", payload);
    }, 2000);
     this.getAllResponse()
  },
  watch: {
    getPageItem(newValue, oldValue) {
      if (newValue != oldValue) {
        this.pageItem = newValue;
      }
    },
    gettotalanswered(newValue, oldValue) {
      if (newValue != oldValue) {
        this.totalanswered = newValue;
      }
    },
  },

  methods: {
    async getAllResponse() {
      this.Loaderstatus = true;
      let payloads = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        userID: this.userID,
        attemptNo: this.testDetails.attemptNo,
      };
      const res = await getTestResponse(
        payloads.topicID,
        payloads.testID,
        payloads.groupID,
        payloads.userID,
        payloads.attemptNo
      );
      this.transformedDatawithcoding = res.data.data.testResponse
        .filter((item) => item.codingSection)
        .map((item) => {
          return {
            ...item         };
        });

    },
    openCodingQuetionFromTestScreen(data, sectionindex, index,secindex,section) {
      this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", index);
      this.$store.commit("codingVUEX/storemoveBtn", index);
      this.$store.commit("testVUEX/storeQuetionsForProgramming", sectionindex);
      this.$store.commit("codingVUEX/datafortryquestion", data);
      this.$store.commit("codingVUEX/savetryQuestion", true);
      this.$store.commit("testVUEX/saveSelectedSection", section);
    },
    selectcodingquestion(data, section) {
      this.$store.commit("testVUEX/saveSelectedSection", section);
      this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", data);
      this.$store.commit("codingVUEX/openCodingSection", true);
      this.$store.commit("testVUEX/storeQuetionsForProgramming", data);
    },
    async select(section, questionKey, index, secindex) {
      this.$store.commit("codingVUEX/openCodingSection", false);
      await this.selectSection(section, index, secindex);
      await this.goto(questionKey);
    },
    async selectSection(section, index, secindex) {
      this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", null);
      this.$store.commit("codingVUEX/openCodingSection", false);
      if (section.sectionID != this.selectedSection.sectionID) {
        setTimeout(() => {
          document
            .querySelector(".selected-section-progress")
            .scrollIntoView({ behaviour: "smooth", align: true });
        }, 200);
      }
      let index1 = this.sectionDetails.findIndex(
        (i) => i.sectionID === section.sectionID
      );
      let data = {
        value: index1,
        status: "set",
      };
      this.$store.commit("sectionVUEX/saveTabIndex", data);
      this.$store.commit("testVUEX/saveSelectedSection", section);
      this.items = this.questionData;
      this.$store.commit(
        "questionVUEX/noOfPages",
        Math.ceil(this.items.length / this.testDetails.config.quesPerScreen)
      );
      for (let j in this.questionData) {
        this.items[j].id = j;
      }
      this.$store.commit("questionVUEX/allItem", this.items);

      const between = (x, min, max) => {
        return x >= min && x <= max;
      };
      let firstindex;
      let lastIndex;
      for (let i = 1; i <= this.noOfPages; i++) {
        lastIndex = this.noOfQues * i;
        firstindex = lastIndex - this.noOfQues;
        if (between(index, firstindex, lastIndex)) {
          this.$store.commit("sectionVUEX/prevnextbtnstatus", secindex);
          this.$store.commit("questionVUEX/saveNoPage", i);
          this.$store.commit("questionVUEX/lastQuestionIndex", lastIndex);
          this.$store.commit(
            "questionVUEX/pageItems",
            this.items.slice(firstindex, lastIndex)
          );
        }
      }

      let payload = {};
      payload.testID = this.testDetails.testID;
      payload.topicID = this.testDetails.topicID;
      payload.groupID = this.testDetails.groupID;

      payload.attemptNo = this.testDetails.attemptNo;
      this.$store.dispatch("testVUEX/testCount", payload);
      if (section.sectionName != "programming") {
        this.$nextTick(() => {
          this.goto(this.questionData[0].questionKey);
        });
      }
      //  })
    },
    async goto(questionKey) {
      // this.$nextTick(()=>{
      document
        .getElementById("ques" + questionKey + this.selectedSection.sectionID)
        .scrollIntoView({ behaviour: "smooth", align: true });

      // })
    },
  },
};
</script>

<style>
.showallbuttonsingrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(44px, 1fr));
  gap: 8px;
  justify-items: center;
}
.showCodingQuestions {
  padding: 10px 10px;
  margin-top: 10px;
}
.drow {
  border: 1px solid #c3c3c3;
  border-radius: 50px;
  height: 31px;
  width: 250px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 0;
  margin-left: 30px;
}

.__progress-button .__progress[data-v-ac48f7e0] {
  border-radius: 40px;
  height: 31px;
  width: 310px;
}

.radio-1 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: absolute;
  background-color: #707070;
  content: "";
  display: inline-block;
  visibility: visible;
  border: none;
}
.radio-2 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: absolute;
  background-color: #85cc82;
  content: "";
  display: inline-block;
  visibility: visible;
  border: none;
}
.radio-3 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: absolute;
  background-color: #dddddd;
  content: "";
  display: inline-block;
  visibility: visible;
  border: none;
}
.radio-4 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: absolute;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #4b4d4e;
}
.radio-5 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: absolute;
  content: "";
  display: inline-block;
  visibility: visible;
  border-color: #c92b2b;
}

.radio-text {
  margin-left: 25px;
  color: var(--header-color);
  font-size: var(--size-16);
  font-weight: var(--weight-R);
}

.lower-progress {
  height: 40px !important;
  border-radius: 20px !important;
  width: 18vw;
  display: flex !important;
  margin-bottom: -30px;
}
.question-attempted {
  color: #ffffff;
  background-color: #85cc82;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.question-attempted-requ {
  color: #ffffff;
  background-color: #85cc82;
  border: 2px solid #de3163;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.barText-top {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radioText-contain {
  position: absolute;
  bottom: 0px;
  width: 90% !important;
}
.pgbar-section {
  background: #ffffff;
  border: 0.5px solid rgba(140, 140, 140, 0.33);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.17);
  border-radius: 8px;
}
.pgbar-secname {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
}
.pgbar-outerdiv {
  overflow: auto;
  height: calc(100vh - 410px);
}
</style>
