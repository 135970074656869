  nb<template>
  <div>
    <div
      class="quescard d-flex relativeclass"
      :id="'ques' + data.questionKey + selectedSection.sectionID"
    >
      <div
      :style="data.questionBody.options.length == 2 && data.questionBody.questionMedia === null ? 'height:90%':''"
        class="quescard-colorbar ml-2"
        :class="
          data.answerStatus.later
            ? 'side-blocks-later' 
            : data.answerStatus.attempted
            ? 'side-blocks-attempted'
            : data.questionBody.config.config.required
            ? 'side-blocks-active'
            : 'side-blocks-unattempted'
        "
      ></div>
      <div class="d-flex flex-column mcqcard">
        <b-row class="mb-2">
          <b-col>
            <div class="d-flex align-items-center">
              <div class="quescard-mainhead">MCQ's</div>
              <div
                class="marktagbox marginmcq"
                v-if="testDetails.config.dispMarks"
              >
                {{ data.questionBody.marks }} Marks
              </div>
            </div>
          </b-col>
          
          <b-col class="d-flex justify-content-end">
            <template v-if="testDetails.config.dispReqStatus">
        <div
          class="reqd-txt mr-3"
          v-if="data.questionBody.config.config.required"
        >
          <img
            src="../../assets/newimgs/testscreen/reqQuesIcon.svg"
            alt=""
            class=""
          />
          Required
        </div>
      </template>
            <div
              class="
              markforlatercheckbox 
              dodontcheckbox
                d-flex
                align-items-center
                justify-content-end
              "
            >
              <b-form-checkbox
                name="checkbox-1"
                v-model="data.answerStatus.later"
                @change="markforLater()"
              >
                Mark for later
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex flex-column">
          <div class="sec-ques-style d-flex">
            <div>Q{{ showindex }}.</div>
            <div
              class="ml-1 sec-ques-style topicDescBreak"
              v-html-safe="data.questionBody.question"
            ></div>
          </div>
          <div v-if="data.questionBody.questionMedia != null" class="mb-3">
            <div
              v-if="isImage(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <img
                class="qb-image-preview"
                :src="data.questionBody.questionMedia"
                alt=""
              />
            </div>
            <div
              v-if="isAudio(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <Media
                :kind="'audio'"
                :isMuted="false"
                :src="data.questionBody.questionMedia"
                :autoplay="false"
                :controls="true"
                :loop="true"
                class="mcqdatacard-audio"
              ></Media>
            </div>
            <div
              v-if="isVideo(data.questionBody.questionMediaType)"
              class="d-flex align-items-center justify-content-center"
            >
              <Media
                :kind="'video'"
                :isMuted="false"
                :src="data.questionBody.questionMedia"
                :autoplay="false"
                :controls="true"
                :loop="true"
                class="qb-image-preview"
              ></Media>
            </div>
          </div>
       
          <div class="ml-3 newblueradios mcqlaptopradio pb-2 mob-radio-group">
            <b-form-radio-group
              @change="selectedOption()"
              value-field="value"
              text-field="value"
              size="sm"
              v-model="data.answer"
              :options="data.questionBody.options"
              class="radio-btns"
            ></b-form-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/com position-api'
import { isImage, isVideo, isAudio } from "../../checkFileType.js";
import Media from "@dongido/vue-viaudio";
import { mapGetters } from "vuex";

export default {
  name: "mcqAnswercard",
  components: {
    Media,
  },
  props: {
    data: Object,
    showindex: Number,
  },
  data() {
    return {
      markLater: false,
      rad_option: null,
      option: "MCQ",
    };
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
    ...mapGetters("testVUEX", {
      selectedSection: "getSelectedSection",
    }),
    ...mapGetters("questionVUEX", {
      currentQuestionData: "getCurrentQuestionData",
    }),
  },
  methods: {
    isImage,
    isVideo,
    isAudio,
    markforLater() {
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.data.questionKey,
        answer: this.data.answer,
        answerStatus: this.data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      console.log(payload);

      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
    selectedOption() {
  
      this.data.answerStatus.unattempted = false;
      this.data.answerStatus.attempted = true;

      
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.selectedSection.sectionID,
        questionKey: this.data.questionKey,
        answer: this.data.answer,
        answerStatus: this.data.answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.$store.commit("testVUEX/updateSectionDetails", payload);
    },
  },
};
</script>

<style>
@import "../../assets/globalcss/style.css";
.mob-radio-group .custom-control-inline {
  width: 50%;
  margin-right: 0px;
}

.mob-radio-group .custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 31px;
  padding-top: 11px;
}

.mob-radio-group .custom-control-label {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #3a3c40;
  max-width: 100%;
  min-width:fit-content;
  word-break: break-all;
  cursor: pointer;
} 
 
.mob-radio-group .custom-control-label::before {
  border: 2px solid #20639b;
}
 
.mob-radio-group .custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  border: 1px solid #20639b;
  border-radius: 50%;
}
 
.mob-radio-group .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-width: 4px;
  border-color: #fff;
  background-color: #20639b;
}
 
.mob-radio-group
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none;
  border: 2px solid #20639b;
  border-radius: 50%;
}
</style>
