<template lang="">
  <div class="position-relative">
    <div v-if="isToastVisible" class="lang-change-toast">
      <div>Language preference has changed</div>
    </div>
    <div class="cq-main-test">
      <div class="cq-main-test-box d-flex">
        <div class="cq-main-test-left-cont mr-3" style="width: 45%;">
          <div class="d-flex">
            <div class="cq-inside-box mr-3">
              {{ dataForQuestion.marks }} Mark{{
                dataForQuestion.marks > 1 ? "s" : ""
              }}
            </div>
            <div class="cq-inside-box mr-3">
              Difficulty Level-&nbsp;{{ difficultyLevelText }}
            </div>
            <div class="cq-inside-box">
              Category-&nbsp; {{ dataForQuestion.category }}
            </div>
          </div>
          <!-- <div class="cq-tag mt-3">
            Tags :
            <span
              v-for="(item, index) in dataForQuestion.questionTags"
              :key="index"
              class="cq-inside-box ml-2"
              >{{ item }}</span
            >
          </div> -->
          <div class="cq-ques-no mt-3">
            Q{{ dataforoverviewchangei3 + 1 }}: {{ dataForQuestion.title }}
          </div>

          <div
            class="cq-ques mt-2"
            v-html-safe="dataForQuestion.question"
          ></div>
          <hr />
          <div class="">
            <span class="cq-sub-head">Constrain</span>
            <div class="mt-2">
              <ul>
                <li
                  class="cq-tag"
                  v-html-safe="dataForQuestion.constraints"
                ></li>
              </ul>
            </div>
          </div>
          <hr />
          <div class="">
            <span class="cq-sub-head">Sample</span>
            <div class="mt-2 cq-tag">
              <div class="d-flex">
                Input : nums =
                <span
                  class="ml-1"
                  v-html-safe="dataForQuestion.sampleInput"
                ></span>
              </div>
              <div class="d-flex mt-2">
                Output :
                <span
                  class="ml-1"
                  v-html-safe="dataForQuestion.sampleOutput"
                ></span>
              </div>
            </div>
          </div>
          <div class="cq-testcase-box mt-3">
            <div class="cq-sub-testcase m-1">
              Test cases
            </div>
            <div class="mss-subheadtext m-1">
              <img class="mr-1" src="../../assets/greyInfo.svg" alt="" />
              These are all visible test cases
            </div>
            <div class="d-flex mt-2">
              <div class="cq-many-testCase-left pointer" style="width: 25%;">
                <div
                  class="p-2  "
                  v-for="(i, index) in visibleTestCases"
                  :key="index"
                  :class="[
                    selectedTestCase === index
                      ? 'cq-many-testCase-sel-text'
                      : 'cq-many-testCase-text',
                  ]"
                  @click="showTestCase(i, index)"
                >
                  Test Case {{ index + 1 }}
                </div>
              </div>
              <div class="cq-many-testCase-right" style="width: 75%;">
                <div class="cq-TC-card p-2 pl-3 mb-2 mx-2">
                  <span class="cq-preview-head">Input</span>
                  <div class="cq-tag-inputs ml-3 mt-2">
                    {{ selectedTestCaseData.inputValue }}
                  </div>
                </div>
                <div class="cq-TC-card p-2 pl-3 mb-2 mx-2">
                  <span class="cq-preview-head">Output</span>
                  <div class="cq-tag-inputs ml-3 mt-2">
                    {{ selectedTestCaseData.outputValue }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="cq-main-test-right-cont overflow-auto p-3"
          style="width: 55%;"
        >
          <div class="cq-main-test-right-top-cont">
            <div class="d-flex justify-content-end">
              <div class="d-flex align-items-center mr-2 cq-lang-text">
                <div
                  class="cq-notsubmitted"
                  v-if="
                    !alldataForQuestion.answerStatus.attempted && !submitting
                  "
                >
                  Not Submitted
                </div>
                <div v-if="submitting == true">
                  <img
                    class="img-height mr-1"
                    src="../../assets/coding-pending.gif"
                    alt=""
                    srcset=""
                  />Pending
                </div>
                <div
                  v-if="
                    alldataForQuestion.answerStatus.attempted && !submitting
                  "
                >
                  <img
                    class="img-height mr-1"
                    style="margin-top: -2.3px;"
                    src="../../assets/codingsubmitted.svg"
                    alt=""
                    srcset=""
                  />Submitted
                </div>
              </div>
              <div style="width: 35%;" class="position-relative">
                <div
                  @click="showLang = !showLang"
                  class="cq-lang-container d-flex align-items-center justify-content-between pointer"
                >
                  Selected Language
                  <div class="cq-langline mx-2"></div>

                  {{ currentlang }}
                  <img class="ml-3" src="../../assets/downarrow.svg" alt="" />
                </div>
                <div v-if="showLang" class="cq-selectLang-cont">
                  <b-form-group
                    class="cq-typeradio"
                    v-for="(data, index) in allactiveForcode"
                    :key="index"
                  >
                    <b-form-radio
                      :value="data.languageName"
                      v-model="currentlang"
                      @change="getTopicValue(data)"
                      :style="
                        currentlang == data.languageName
                          ? 'background-color: #e8eff4'
                          : ''
                      "
                    >
                      {{ data.languageName }}
                    </b-form-radio>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="position-relative">
              <div class="cq-black mt-3 editorContainer"></div>
              <div
                v-if="openOverview == false"
                @click="(openOverview = true), (showLang = false)"
                class="cq-code-progress pointer tq-tooltip-trigger"
              >
                <div class="tq-tooltip">
                  Overview
                </div>
                <img src="../../assets/codeProgress.svg" alt="" />
              </div>
            </div>

            <div
              class="d-flex justify-content-end bluebtns mt-3 position-relative"
            >
              <b-overlay
                :show="submitting"
                :style="
                  alldataForQuestion.answerStatus.attempted
                    ? 'left:64px'
                    : 'left:58px'
                "
              ></b-overlay>
              <b-button
                @click="submitAnswer(dataForQuestion)"
                variant="outline-primary"
                class="mr-4 pr-4 pl-4"
                >{{
                  alldataForQuestion.answerStatus.attempted
                    ? "Update Code"
                    : "Save Code"
                }}</b-button
              >
              <div></div>
              <b-overlay :show="codeRunning">
                <b-button
                  variant="primary"
                  @click="runCode(dataForQuestion), (codeRunning = true)"
                  class="pr-4 pl-4"
                >
                  Run Code</b-button
                >
              </b-overlay>
            </div>
          </div>

          <div
            v-if="showResult"
            ref="scrollContainer"
            class="cq-main-test-right-bottom-cont bg-white"
          >
            <hr class="mb-1" />
            <div class="cq-test-result p-2">
              Test Result
            </div>
            <div class="cq-test-result-cont p-2">
              <div v-if="failedtestcase > 0">
                <div class="cq-topRed">
                  Compilation Failed -
                  {{ failedtestcase }}/{{ dataForQuestion.testCases.length }}
                  test cases Failed
                </div>
                <div class="p-2" v-if="compileMessage.stderr">
                  <span class="cq-preview-head">
                    Compile Message
                  </span>
                  <div class="cq-tag-blue-box mt-3">
                    {{ compileMessage.stderr }}
                  </div>
                </div>
              </div>
              <div class="cq-topGreen" v-else>
                Completed Successfully - All test cases are passed
              </div>
              <hr class="mt-2 mb-0" />
              <div class="p-2">
                <span class="cq-preview-head">Test Cases</span>
                <div class="d-flex mt-2">
                  <div class="tc-many-trialCase-left" style="width: 25%;">
                    <!-- tc-many-testCase-text-green -->
                    <!-- tc-many-testCase-text-disable -->
                    <div
                      class="tc-many-testCase-text-grey d-flex justify-content-between align-items-center"
                      v-for="(data, i) in executionData"
                      :key="i"
                      @click="data.visible ? hideIcon(i, data) : ''"
                      :class="[
                        data.visible ? 'pointer' : 'not-allowed',
                        showIcon == i ? 'IncfontSize' : '',
                      ]"
                    >
                      <div class="d-flex align-items-center">
                        <img
                          v-if="
                            data.stdout &&
                              data.stdout.replace(/\n/g, '') == data.outputValue
                          "
                          class="mr-1"
                          src="../../assets/testCasePass.svg"
                          alt=""
                        />
                        <img
                          v-else
                          class="mr-1"
                          src="../../assets/testCaseFail.svg"
                          alt=""
                        />
                        Test Case {{ i + 1 }}
                      </div>
                      <img
                        v-if="!data.visible"
                        class="ml-2"
                        src="../../assets/nonView.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="tc-many-testCase-right" style="width: 75%;">
                    <div class="cq-TC-card p-2 pl-3 mb-2 mx-2">
                      <span class="testdetails-simpledetails-subtxt"
                        >Input</span
                      >
                      <div class="cq-tag-inputs ml-3 mt-2">
                        {{ showTestCaseData.inputValue }}
                      </div>
                    </div>
                    <div class="cq-TC-card p-2 pl-3 mb-2 mx-2">
                      <span class="testdetails-simpledetails-subtxt"
                        >Output</span
                      >
                      <div class="cq-tag-inputs ml-3 mt-2">
                        {{ showTestCaseData.outputValue }}
                      </div>
                    </div>
                    <div class="cq-TC-card p-2 pl-3 mb-2 mx-2">
                      <span class="testdetails-simpledetails-subtxt"
                        >Output</span
                      >
                      <div class="cq-tag-inputs ml-3 mt-2">
                        {{ showTestCaseData.stdout || "null" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cq-footer d-flex align-items-center justify-content-between">
        <div class="bluebtns">
          <b-button
            variant="outline-primary"
            class="pr-3 pl-3"
            @click="gobacktosction()"
            >Back to Sections</b-button
          >
        </div>
        <div v-if="currentCodingSection.length > 1" class="bluebtns">
          <b-button
            v-if="moveBtn != 0"
            @click="gobackToPrevQuestion()"
            class="mr-4 pr-4 pl-4"
            variant="primary"
            >Previous Question</b-button
          >
          <b-button
            v-if="moveBtn != currentCodingSection.length - 1"
            @click="openAlertModal()"
            variant="primary"
            class="pr-4 pl-4"
            >Next Question</b-button
          >
        </div>
      </div>
    </div>
    <Transition name="slide">
      <div v-if="openOverview" class="cq-slider">
        <div class="d-flex align-items-center justify-content-between ml-4">
          <div class="cq-overview-text">Overview</div>
          <div class="pointer rotate-on-hover">
            <img
              @click="openOverview = false"
              src="../../assets/save-modal-x.svg"
              alt=""
            />
          </div>
        </div>
        <hr />
        <div class="position-relative">
          <div class="cq-quitProgress">
            <img
              @click="openOverview = false"
              src="../../assets/quitProgress.svg"
              alt=""
            />
          </div>
          <div class="cq-slider-overflow">
            <div v-for="(data, indexp1) in 1" :key="indexp1">
              <div
                v-for="(datas, indexp2) in transformedDatawithcoding"
                :key="indexp2"
                class="cq-overview-card p-3 m-2 pointer"
                @click.stop="updateSectionData(indexp2, datas)"
                :class="
                  storeQuetionsForcodingall == indexp2
                    ? 'selected-card-section'
                    : ''
                "
              >
                <div class="cq-overview-subtext ml-1">
                  <b>Section {{ indexp2 + 1 }}</b>
                </div>
                <div class="d-flex">
                  <b-row>
                    <div
                      class="ml-3"
                      v-for="(data, indexc) in datas.sectionResponse"
                      :key="indexc"
                    >
                      <div
                        class="mt-3 pointer text-center"
                        @click.stop="
                          dataforoverviewchangei3 != indexc ||
                          dataforoverviewchangei2 != indexp2
                            ? changeQuetionInOverviewupdate(
                                data,
                                indexp1,
                                indexp2,
                                indexc
                              )
                            : ''
                        "
                        :class="[
                          dataforoverviewchangei1 == indexp1 &&
                          dataforoverviewchangei2 == indexp2 &&
                          dataforoverviewchangei3 == indexc &&
                          data.answerStatus.attempted
                            ? 'cq-selected-green-outer d-flex justify-content-center'
                            : data.answerStatus.attempted
                            ?storeQuetionsForcodingall == indexp2?'cq-red-overview-secselc cq-green-overview': 'cq-green-overview'
                            : dataforoverviewchangei1 == indexp1 &&
                              dataforoverviewchangei2 == indexp2 &&
                              dataforoverviewchangei3 == indexc &&
                              !data.answerStatus.attempted
                            ? 'cq-selected-red-outer d-flex justify-content-center'
                            : storeQuetionsForcodingall == indexp2?'cq-red-overview-secselc cq-red-overview':'cq-red-overview',
                        ]"
                      >
                        <!-- :class="
                            dataforoverviewchangei1 == indexp1 &&
                            dataforoverviewchangei2 == indexp2 &&
                            dataforoverviewchangei3 == indexc
                              ? data.answerStatus.attempted
                                ? 'cq-green-inner'
                                : 'cq-red-inner'
                              : ''
                          " -->
                        <div class=" text-center">Q{{ indexc + 1 }}</div>
                      </div>
                    </div>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <div v-if="modalalert" class="testscreen-custom-modal">
      <div class="testscreen-modal-content-1">
        <div
          @click="modalalert = false"
          class="x-mark-modal rotate-on-hover pointer"
        >
          <img src="../../assets/save-modal-x.svg" alt="" />
        </div>
        <div class="d-flex">
          <div class="mr-3">
            <img src="../../assets/featured_icon.svg" alt="" />
          </div>
          <div>
            <div class="save-txt ">
              Save the question
            </div>
            <div class="save-modal-head mt-1">
              Please save the question before moving on to other questions.
            </div>
            <div class="save-modal-subtxt mt-2">
              This question will be saved. You can try this question again.
            </div>
          </div>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-end mt-3 p-2"
        >
          <div class="cancel-btn mr-3 pointer" @click="dontSave()">
            Cancel
          </div>
          <b-button
            @click="moveForwardForNextQuestion(dataForQuestion)"
            variant="primary"
            >Save and Go to Next</b-button
          >
        </div>
      </div>
    </div>
    <div v-if="modalalertprev" class="testscreen-custom-modal">
      <div class="testscreen-modal-content-1">
        <div class="save-txt d-flex align-items-center justify-content-between">
          Save the question
        </div>
        <div
          @click="modalalertprev = false"
          class="x-mark-modal rotate-on-hover pointer"
        >
          <img src="../../assets/save-modal-x.svg" alt="" />
        </div>
        <div class="d-flex">
          <div class="mr-3">
            <img src="../../assets/featured_icon.svg" alt="" />
          </div>
          <div>
            <div class="save-modal-head mt-1">
              Please save this question before moving on to other questions
            </div>
            <div class="save-modal-subtxt mt-2">
              This question will be saved. You can try this question again.
            </div>
          </div>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-end mt-3 p-2"
        >
          <div class="cancel-btn mr-3 pointer" @click="dontSave()">
            Cancel
          </div>
          <b-button
            @click="moveBackForNextQuestion(dataForQuestion)"
            variant="primary"
            >Save and Go to Previous</b-button
          >
        </div>
      </div>
    </div>
    <div v-if="modalalertover" class="testscreen-custom-modal">
      <div class="testscreen-modal-content-1">
        <div class="save-txt d-flex align-items-center justify-content-between">
          Save the question
        </div>
        <div class="x-mark-modal rotate-on-hover pointer">
          <img
            @click="modalalertover = false"
            src="../../assets/save-modal-x.svg"
            alt=""
          />
        </div>
        <div class="d-flex">
          <div class="mr-3">
            <img src="../../assets/featured_icon.svg" alt="" />
          </div>
          <div>
            <div class="save-modal-head mt-1">
              Please save this question before moving on to other questions
            </div>
            <div class="save-modal-subtxt mt-2">
              This question will be saved. You can try this question again.
            </div>
          </div>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-end mt-3 p-2"
        >
          <div class="cancel-btn mr-3 pointer" @click="dontSave()">
            Cancel
          </div>
          <b-button
            @click="
              moveForwardForNextQuestionover(
                dataforoverviewchange,
                change1,
                change2,
                change3
              )
            "
            variant="primary"
            >Save and Go to Next</b-button
          >
        </div>
      </div>
    </div>
    <div v-if="modalalertleave" class="testscreen-custom-modal">
      <div class="testscreen-modal-content-1">
        <div
          @click="modalalertleave = false"
          class="x-mark-modal rotate-on-hover pointer"
        >
          <img src="../../assets/save-modal-x.svg" alt="" />
        </div>
        <div class="d-flex">
          <div class="mr-3">
            <img src="../../assets/featured_icon.svg" alt="" />
          </div>
          <div>
            <div class="save-txt">
              Save the question
            </div>
            <div class="save-modal-head mt-1">
              Please save this question before moving on to other questions
            </div>
            <div class="save-modal-subtxt mt-2">
              This question will be saved. You can try this question again.
            </div>
          </div>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-end mt-3 p-2"
        >
          <div class="cancel-btn mr-3 pointer" @click="dontSave()">
            Cancel
          </div>
          <b-button
            @click="moveForwardForNextQuestionleave(dataForQuestion)"
            variant="primary"
            >Save and Go to Sections</b-button
          >
        </div>
      </div>
    </div>
    <!-- <div
      v-if="submitcodetestmodal"
      class="mt-3 position-absolute"
      style="top: 33%;left: 26%;width: 41%;"
    >
      <div
        class="d-flex align-items-center h-100 justify-content-center flex-column align-items-center"
      >
        <div class="w-75 completed-card-container p-5 position-relative">
          <div class="cmpltedsucc-icon ">
            <img src="../../assets/newimgs/compltedicon.svg" alt="" />
          </div>
          <div
            class="d-flex align-items-center flex-column justify-content-center mt-3 p-4"
          >
            <div class="mt-3 fdbck-question-txt" style="font-weight: 700;">
              Code Submitted Successfully.
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <transition name="drop">
      <div
        class="test-timerdiv-submit d-flex justify-content-around align-items-center"
        v-if="submitcodetestmodal"
      >
        <div class="d-flex">
          <div class="d-flex align-items-center ml-2">
            <img src="../../assets/success_tick.svg" />
          </div>
          <div class="d-flex align-items-center ml-2">
            {{
              alldataForQuestion.answerStatus.attempted
                ? "The code has been updated successfully."
                : "The code has been saved successfully."
            }}
          </div>
        </div>

        <div class="mr-1">
          <img class="rotate-on-hover" src="../../assets/grey_closeicon.svg" />
        </div>
      </div>
    </transition>
  </div>
  <!-- ---------------------------------------------------------------- -->
</template>

<script>
import {
  // getcodesnippetcompiler,
  // testCaseRunner,
  // getcodesnippetcompilertestCase,
  codecompileforeachcase,
  getTestResponse,
  savecodesnppet,
} from "../../apiFunction";
import { mapGetters } from "vuex";
import successToast from "../../mixins/toast";

export default {
  name: "trycodeQues",
  mixins: [successToast],
  data() {
    return {
      isToastVisible: false,
      codeRunning: false,
      submitting: false,
      compileMessage: {},
      executionData: [],
      failedtestcase: 0,
      modalalert: false,
      modalalertprev: false,
      modalalertover: false,
      modalalertleave: false,
      selectedTestCaseData: {},
      currentCodingSectionAll: {},
      currentCodingQuetion: {},
      currentCodingSection: [],
      currentLangId: null,
      currentbody: null,
      currentlang: null,
      allTestCases: [],
      change1: null,
      change2: null,
      change3: null,
      dataforoverviewchange: null,
      dataforoverviewchangei1: 0,
      dataforoverviewchangei2: null,
      dataforoverviewchangei3: 0,
      showIcon: null,
      storeParentIndex: null,
      storeChildIndex: null,
      storeChildIndex2: null,
      storeParentIndexop1: null,
      storeChildIndex01: null,
      storeChildIndexo2: null,
      showTestCaseData: {},
      // moveBtn: 0,
      selectedTestCase: 0,
      allactiveForcode: [],
      compiledTestCasesResult: {},
      editorInstance: null,
      showLang: false,
      openOverview: false,
      submitcodetestmodal: false,
      loopVar2: 4,
      loopVar: 10,
      value: 1,
      max: 7,
      showResult: false,
      items: [],
      transformedDatawithcoding: [],
    };
  },
  computed: {
    ...mapGetters("codingVUEX", {
      alldataForQuestion: "getdataForQuestion",
      moveBtn: "getmoveBtn",
    }),
    ...mapGetters("testVUEX", {
      storeQuetionsForcodingall: "getstoreQuetionsForcodingall",
      sectionDetails: "getSectionDetails",
      selectedSection: "getSelectedSection",
      questiontoexecute: "getstoreQuestiontoexecute",
      getexecute: "getexecute",
      storeQuetionschildindex: "getstorestoreQuetionschildindex",
      storeQuetionparentIndex: "getstorestoreQuetionparentIndex",
    }),
    ...mapGetters("sectionVUEX", {
      userID: "getUserID",
      testDetails: "getTestDetails",
      tabIndexFromStore: "getTabIndex",
    }),

    difficultyLevelText() {
      if (
        this.dataForQuestion.difficultyLevel === 1 ||
        this.dataForQuestion.difficultyLevel === 2
      ) {
        return "Easy";
      } else if (
        this.dataForQuestion.difficultyLevel === 3 ||
        this.dataForQuestion.difficultyLevel === 4
      ) {
        return "Medium";
      } else return "Hard";
    },
    currentquestiondata() {
      let data = Object.entries(
        this.dataForQuestion.defaultBody
      ).map(([key, value]) => ({ id: key, ...value }));
      let filteredLangData = data.filter(
        (data) => data.languageName == this.currentlang
      );
      return filteredLangData[0];
    },
    sectionData() {
      let arrayofdata = [];
      this.sectionDetails.map((data) => {
        if (data.sectionName == "programming") {
          arrayofdata.push(data);
        }
      });
      return arrayofdata;
    },
    dataForQuestion() {
      return this.alldataForQuestion.questionBody;
    },
    visibleTestCases() {
      let arr = [];
      this.alldataForQuestion.questionBody.testCases.map((data) => {
        if (data.visible) {
          arr.push(data);
        }
      });
      return arr;
    },
  },
  watch: {
    allTestCases() {
      this.allTestCases.map((data) => {
        data.testCaseResult.map((datas) => {
          if (datas.visible) {
            this.hideIcon(0, this.allTestCases[0].testCaseResult[0]);
          }
        });
      });
    },
  },
  mounted() {
    // window.addEventListener("resize", this.zoomInout);
    this.showTestCase(this.dataForQuestion.testCases[0], 0);
    this.dataforoverviewchangei2 = this.storeQuetionsForcodingall;
    this.dataforoverviewchangei3 = this.moveBtn;
  },
  // beforeUnmount() {
  //   window.removeEventListener("resize", this.zoomInout);
  // },
  async created() {
    await this.getAllResponse();
    let arrayFromData = Object.entries(
      this.dataForQuestion.defaultBody
    ).map(([key, value]) => ({ id: key, ...value }));
    if (this.dataForQuestion.answer) {
      this.currentLangId = this.dataForQuestion.answer.languageID;
      this.currentbody = this.dataForQuestion.answer.body;
      this.currentlang = this.dataForQuestion.answer.languagename;
    } else {
      this.currentLangId = arrayFromData[0].id;
      this.currentbody = arrayFromData[0].body;
      this.currentlang = arrayFromData[0].languageName;
    }
    console.log(this.currentbody,"created cb")
    this.initializeEditor();
  },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.zoomInout);
  // },
  methods: {
    // capitalizeFirstLetter(str) {
    //   return str.charAt(0).toUpperCase() + str.slice(1);
    // },
    updateSectionDetailsCoding() {
      let answerStatus = {
        active: true,
        attempted: true,
        later: false,
        unattempted: false,
      };
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.currentCodingSectionAll.sectionID,
        questionKey: this.dataForQuestion.questionKey,
        answer: {
          languageID: this.currentLangId,
          languagename: this.currentlang,
          body: this.currentbody,
        },
        answerStatus: answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.commit("testVUEX/updateCodingSectionDetails", {
        payload: payload,
        sectionID: this.currentCodingSectionAll.sectionID,
      });
      this.alldataForQuestion.answer = payload.answer;
      this.$store.commit(
        "codingVUEX/datafortryquestion",
        this.currentCodingSection.filter(
          (ques) => ques.questionKey == this.alldataForQuestion.questionKey
        )[0]
      );
    },
    dontSave() {
      this.modalalert = false;
      this.modalalertprev = false;
      this.modalalertover = false;
      this.modalalertleave = false;
    },
    zoomInout() {
      // this.currentbody = this.editorInstance.getValue();
      // this.removeEditor();
      // this.initializeEditor();
    },
    async handleClickOutside() {
      if (this.currentbody != this.editorInstance.getValue()) {
        let payload = {
          topicID: this.testDetails.topicID,
          testID: this.testDetails.testID,
          groupID: this.testDetails.groupID,
          sectionID: this.currentCodingSectionAll.sectionID,
          questionKey: this.dataForQuestion.questionKey,
          code: {
            languageID: this.currentLangId,
            languagename: this.currentlang,
            body: this.editorInstance.getValue(),
          },
          attemptNo: this.testDetails.attemptNo,
        };
        await savecodesnppet(payload);
        this.getAllResponse();
        // }
      }
    },
    removeEditor() {
      if (this.editorInstance) {
        this.editorInstance.dispose();
        const editorContainer = document.querySelector(".editorContainer");
        while (editorContainer.firstChild) {
          console.log(editorContainer.firstChild);
          editorContainer.firstChild.remove();
        }
      }
    },
    updateSectionData(index, data) {
      this.$store.commit("testVUEX/storeQuetionsForProgramming", index);
      this.$store.commit(
        "codingVUEX/datafortryquestion",
        data.sectionResponse[0]
      );
      this.changeQuetionInOverviewupdate(data.sectionResponse[0], 0, index, 0);
      this.$store.commit("codingVUEX/storemoveBtn", 0);
    },
    async getAllResponse() {
      let payloads = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        userID: this.userID,
        attemptNo: this.testDetails.attemptNo,
      };
      const res = await getTestResponse(
        payloads.topicID,
        payloads.testID,
        payloads.groupID,
        payloads.userID,
        payloads.attemptNo
      );
      this.transformedDatawithcoding = res.data.data.testResponse
        .filter((item) => item.codingSection)
        .map((item) => {
          return {
            ...item,
            attemptedCount: 0,
          };
        });

      this.currentCodingSectionAll = this.transformedDatawithcoding[
        this.storeQuetionsForcodingall
      ];
      this.currentCodingSectionAll.sectionResponse.filter((data) => {
        if (this.dataForQuestion.questionKey) {
          if (this.dataForQuestion.questionKey == data.questionKey) {
            let arrayFromData = Object.entries(
              data.questionBody.defaultBody
            ).map(([key, value]) => ({ id: key, ...value }));
            this.allactiveForcode = arrayFromData;
            if (data.answer) {
              this.dataForQuestion["answer"] = data.answer;
            }
          }
        }
      });
      this.currentCodingSection = this.transformedDatawithcoding[
        this.storeQuetionsForcodingall
      ].sectionResponse;

      this.$store.commit(
        "codingVUEX/datafortryquestion",
        this.currentCodingSection.filter(
          (ques) => ques.questionKey == this.alldataForQuestion.questionKey
        )[0]
      );
    },
    hideIcon(index, data) {
      setTimeout(() => {
        const scrollContainer = this.$refs.scrollContainer;
        scrollContainer.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 200);
      this.showTestCaseData = data;
      this.showIcon = index;
    },
    moveForwardForNextQuestion(data) {
      this.showResult = false;
      this.currentbody = this.editorInstance.getValue();
      const extractedCode = this.editorInstance.getValue();
      let answerStatus = {
        active: true,
        attempted: true,
        later: false,
        unattempted: false,
      };
      let payloads = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        userID: this.userID,
        attemptNo: this.testDetails.attemptNo,
      };
      var payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.currentCodingSectionAll.sectionID,
        questionKey: data.questionKey,
        answer: {
          languageID: this.currentLangId,
          languagename: this.currentlang,
          body: extractedCode,
        },
        answerStatus: answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };

      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.updateSectionDetailsCoding();
      // this.$store.dispatch("testVUEX/getTestResponse", payloads);
      // this.getAllResponse();
      this.submitcodetestmodal = true;
      setTimeout(() => {
        this.submitcodetestmodal = false;
        this.submitting = false;
      }, 1000);
      if (this.moveBtn < this.currentCodingSection.length) {
        // this.moveBtn++;
        let total = this.moveBtn + 1;
        this.$store.commit("codingVUEX/storemoveBtn", total);
        this.changeQuetionInOverview(this.currentCodingSection[this.moveBtn]);
      }
      setTimeout(() => {
        this.$store.dispatch("testVUEX/getTestResponse", payloads);
        this.getAllResponse();
      }, 1000);
      // this.$bvModal.hide("modal-alert");
      this.modalalert = false;
      // this.showResult = false;
      // this.submitAnswer(data);
      // let total = this.moveBtn + 1;
      // this.$store.commit("codingVUEX/storemoveBtn", total);
      // this.changeQuetionInOverview(this.currentCodingSection[this.moveBtn]);
      this.dataforoverviewchangei3++;
      // this.modalalert = false;
    },
    moveForwardForNextQuestionleave(data) {
      this.currentbody = this.editorInstance.getValue();

      this.showResult = false;
      const extractedCode = this.editorInstance.getValue();
      let answerStatus = {
        active: true,
        attempted: true,
        later: false,
        unattempted: false,
      };
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.currentCodingSectionAll.sectionID,
        questionKey: data.questionKey,
        answer: {
          languageID: this.currentLangId,
          languagename: this.currentlang,
          body: extractedCode,
        },
        answerStatus: answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.updateSectionDetailsCoding();
      this.submitcodetestmodal = true;
      // this.submitAnswer(data);
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      setTimeout(() => {
        this.submitcodetestmodal = false;
        this.submitting = false;
      }, 1000);
      this.$store.commit(
        "testVUEX/saveSelectedSection",
        this.sectionDetails[this.tabIndexFromStore]
      );
      setTimeout(() => {
        this.$store.commit("codingVUEX/savetryQuestion", false);
        this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", null);
        this.$store.commit("codingVUEX/openCodingSection", false);
        this.modalalertleave = false;
        this.openOverview = false;
      }, 1000);

      // this.removeEditor();
    },
    gobackToPrevQuestion() {
      this.showResult = false;
      if (this.currentbody == this.editorInstance.getValue()) {
        if (this.moveBtn > 0) {
          let total = this.moveBtn - 1;
          this.$store.commit("codingVUEX/storemoveBtn", total);
          this.changeQuetionInOverview(this.currentCodingSection[this.moveBtn]);
          this.dataforoverviewchangei3--;
        }
      } else {
        this.modalalertprev = true;
      }
    },
    moveBackForNextQuestion(data) {
      this.showResult = false;
      this.currentbody = this.editorInstance.getValue();
      const extractedCode = this.editorInstance.getValue();
      let answerStatus = {
        active: true,
        attempted: true,
        later: false,
        unattempted: false,
      };
      let payloads = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        userID: this.userID,
        attemptNo: this.testDetails.attemptNo,
      };
      var payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.currentCodingSectionAll.sectionID,
        questionKey: data.questionKey,
        answer: {
          languageID: this.currentLangId,
          languagename: this.currentlang,
          body: extractedCode,
        },
        answerStatus: answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };

      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.updateSectionDetailsCoding();
      // this.$store.dispatch("testVUEX/getTestResponse", payloads);
      // this.getAllResponse();

      // this.submitAnswer(data);
      this.submitcodetestmodal = true;
      setTimeout(() => {
        this.submitcodetestmodal = false;
        this.submitting = false;
      }, 1000);
      if (this.moveBtn > 0) {
        let total = this.moveBtn - 1;
        this.$store.commit("codingVUEX/storemoveBtn", total);
        this.changeQuetionInOverview(this.currentCodingSection[this.moveBtn]);
        this.dataforoverviewchangei3--;
      }
      setTimeout(() => {
        this.$store.dispatch("testVUEX/getTestResponse", payloads);
        this.getAllResponse();
      }, 1000);
      this.modalalertprev = false;
    },
    changeQuetionInOverviewupdate(data, i1, i2, i3) {
      this.showResult = false;
      if (this.currentbody == this.editorInstance.getValue()) {
        this.$store.commit("testVUEX/storeQuetionsForProgramming", i2);
        this.currentCodingSectionAll = this.transformedDatawithcoding[
          this.storeQuetionsForcodingall
        ];

        this.currentCodingSection = this.transformedDatawithcoding[
          this.storeQuetionsForcodingall
        ].sectionResponse;
        this.moveForwardForNext(data, i1, i2, i3);
        this.dataforoverviewchangei1 = i1;
        this.dataforoverviewchangei2 = i2;
        this.dataforoverviewchangei3 = i3;
        this.$store.commit(
          "codingVUEX/storemoveBtn",
          this.dataforoverviewchangei3
        );
      } else {
        this.change1 = i1;
        this.change2 = i2;
        this.change3 = i3;
        this.dataforoverviewchange = data;
        this.modalalertover = true;
      }
    },
    moveForwardForNext(data, i1, i2, i3) {
      let total = i3 || this.moveBtn;
      this.$store.commit("codingVUEX/storemoveBtn", total);
      this.dataforoverviewchangei1 = i1;
      this.dataforoverviewchangei2 = i2;
      this.dataforoverviewchangei3 = i3;
      this.$store.commit("codingVUEX/datafortryquestion", data);
      this.showTestCase(data.questionBody.testCases[0], 0);
      if (data.answer) {
        this.currentLangId = data.answer.languageID;
        this.currentbody = data.answer.body;
        this.currentlang = data.answer.languagename;
        let arrayFromData = Object.entries(
          data.questionBody.defaultBody
        ).map(([key, value]) => ({ id: key, ...value }));
        this.allactiveForcode = arrayFromData;
        this.removeEditor();
        this.initializeEditor();
      } else {
        let arrayFromData = Object.entries(
          data.questionBody.defaultBody
        ).map(([key, value]) => ({ id: key, ...value }));
        this.currentLangId = arrayFromData[0].id;
        this.currentbody = arrayFromData[0].body;
        this.currentlang = arrayFromData[0].languageName;
        this.allactiveForcode = arrayFromData;
        this.removeEditor();
        this.initializeEditor();
      }
    },
    moveForwardForNextQuestionover(data, i1, i2, i3) {
      this.dataForQuestion.defaultBody.body = this.editorInstance.getValue();
      this.submitAnswer(this.dataForQuestion);
      this.$store.commit("testVUEX/storeQuetionsForProgramming", i2);
      this.currentCodingSectionAll = this.transformedDatawithcoding[
        this.storeQuetionsForcodingall
      ];
      this.currentCodingSection = this.transformedDatawithcoding[
        this.storeQuetionsForcodingall
      ].sectionResponse;
      let total = i3;
      this.$store.commit("codingVUEX/storemoveBtn", total);
      this.dataforoverviewchangei1 = i1;
      this.dataforoverviewchangei2 = i2;
      this.dataforoverviewchangei3 = i3;
      this.showTestCase(data.questionBody.testCases[0], 0);
      if (data.answer) {
        this.currentLangId = data.answer.languageID;
        this.currentbody = data.answer.body;
        this.currentlang = data.answer.languagename;
        this.removeEditor();
        this.initializeEditor();
      } else {
        let arrayFromData = Object.entries(
          data.questionBody.defaultBody
        ).map(([key, value]) => ({ id: key, ...value }));
        this.currentLangId = arrayFromData[0].id;
        this.currentbody = arrayFromData[0].body;
        this.currentlang = arrayFromData[0].languageName;
        this.allactiveForcode = arrayFromData;
        this.removeEditor();
        this.initializeEditor();
      }
      this.$store.commit("codingVUEX/datafortryquestion", data);
      this.modalalertover = false;
    },
    changeQuetionInOverview(data, i1, i2, i3) {
      let total = i3 || this.moveBtn;
      this.$store.commit("codingVUEX/storemoveBtn", total);
      this.storeParentIndexop1 = i1;
      this.storeChildIndex01 = i2;
      this.storeChildIndexo2 = i3;
      this.$store.commit("codingVUEX/datafortryquestion", data);
      this.showTestCase(data.questionBody.testCases[0], 0);
      let arrayFromData = Object.entries(
        data.questionBody.defaultBody
      ).map(([key, value]) => ({ id: key, ...value }));
      this.allactiveForcode = arrayFromData;
      if (data.answer) {
        this.currentLangId = data.answer.languageID;
        this.currentbody = data.answer.body;
        this.currentlang = data.answer.languagename;
        this.removeEditor();
        this.initializeEditor();
      } else {
        this.currentLangId = arrayFromData[0].id;
        this.currentbody = arrayFromData[0].body;
        this.currentlang = arrayFromData[0].languageName;
        this.removeEditor();
        this.initializeEditor();
      }
    },
    submitAnswer(data) {
      this.submitting = true;
      let payloads = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        userID: this.userID,
        attemptNo: this.testDetails.attemptNo,
      };
      const extractedCode = this.editorInstance.getValue();
      this.currentbody = this.editorInstance.getValue();
      let answerStatus = {
        active: true,
        attempted: true,
        later: false,
        unattempted: false,
      };
      let payload = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        sectionID: this.currentCodingSectionAll.sectionID,
        questionKey: data.questionKey,
        answer: {
          languageID: this.currentLangId,
          languagename: this.currentlang,
          body: extractedCode,
        },
        answerStatus: answerStatus,
        attemptNo: this.testDetails.attemptNo,
      };
      this.$store.dispatch("testVUEX/saveAnswer", payload);
      this.updateSectionDetailsCoding();
      this.submitcodetestmodal = true;
      setTimeout(() => {
        this.submitcodetestmodal = false;
        this.submitting = false;
      }, 1000);
      if (!this.alldataForQuestion.answerStatus.attempted) {
        setTimeout(() => {
          this.$store.dispatch("testVUEX/getTestResponse", payloads);
          this.getAllResponse();
        }, 1000);
      }
    },
    async runCode(dataForQuestion) {
      this.failedtestcase = 0;
      this.executionData = [];
      this.showResult = false;
      const extractedCode = this.editorInstance.getValue();
      console.log(dataForQuestion.testCases);
      dataForQuestion.testCases.map(async (data) => {
        let payload = {
          language: this.currentlang,
          stdin: data.inputValue,
          files: [
            {
              name: this.currentquestiondata.properties.name,
              content: extractedCode,
            },
          ],
        };
        try {
          const response = await codecompileforeachcase(payload);
          this.compileMessage = response.data;
          console.log(this.failedtestcase);
          if (response.data.stderr == null) {
            if (response.data.stdout != null) {
              if (response.data.stdout.replace(/\n/g, "") != data.outputValue) {
                this.failedtestcase = this.failedtestcase + 1;
              }
              console.log(data.outputValue);
              this.executionData.push({ ...response.data, ...data });
              console.log(this.executionData);
              this.hideIcon(0, this.executionData[0]);
            } else {
              this.executionData.push({ ...response.data, ...data });
              this.failedtestcase = this.failedtestcase + 1;
              this.hideIcon(0, this.executionData[0]);
            }
          } else {
            this.executionData.push({ ...response.data, ...data });
            this.hideIcon(0, this.executionData[0]);
            this.failedtestcase = this.failedtestcase + 1;
          }
          if (response.status == 200) {
            this.showResult = true;
            this.codeRunning = false;
            const scrollContainer = this.$refs.scrollContainer;
            scrollContainer.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        } catch (error) {
          console.error(error);
        }
      });
    },
    showTestCase(data, index) {
      this.selectedTestCase = index;
      this.selectedTestCaseData = data;
    },
    initializeEditor() {
      let snip = this.currentbody;
      const editorContainer = document.querySelector(".editorContainer");
      window.require.config({
        paths: {
          vs: "https://cdn.jsdelivr.net/npm/monaco-editor@latest/min/vs",
        },
      });
      window.require(["vs/editor/editor.main"], () => {
        this.editorInstance = window.monaco.editor.create(editorContainer, {
          value: snip,
          language: this.currentlang == "c++" ? "cpp" : this.currentlang,
          theme: "vs-dark",
          automaticLayout: true, // Automatically adjust the editor's size based on content.
        });
      });
    },
    getTopicValue(data) {
      // this.isToastVisible = true;
      // setTimeout(() => {
      //   this.isToastVisible = false;
      // }, 2000);
      this.handleClickOutside();
      this.currentLangId = data.id;
      this.currentbody = data.body;
      this.currentlang = data.languageName;
      this.showLang = false;
      this.showResult=false;
      this.removeEditor();
      this.initializeEditor();
    },
    gobacktosction() {
      if (this.currentbody == this.editorInstance.getValue()) {
        this.openOverview = false;
        if (this.editorInstance) {
          this.editorInstance.dispose(); // Dispose the editor instance
          const editorContainer = document.querySelector(".editorContainer");
          while (editorContainer.firstChild) {
            editorContainer.firstChild.remove(); // Remove all child nodes from the editor container
          }
        }
        this.$store.commit("codingVUEX/savetryQuestion", false);

        this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", null);
        this.$store.commit("codingVUEX/openCodingSection", false);
        this.$store.commit(
          "testVUEX/saveSelectedSection",
          this.sectionDetails[this.tabIndexFromStore]
        );
      } else {
        this.modalalertleave = true;
      }
    },
    openAlertModal() {
      this.showResult = false;
      if (this.currentbody == this.editorInstance.getValue()) {
        if (this.moveBtn < this.currentCodingSection.length) {
          let total = this.moveBtn + 1;
          this.$store.commit("codingVUEX/storemoveBtn", total);
          this.changeQuetionInOverview(this.currentCodingSection[this.moveBtn]);
          this.dataforoverviewchangei3++;
        }
      } else {
        this.modalalert = true;
      }
    },
  },
};
</script>
<style>
.tq-tooltip {
  display: none;
  position: absolute;
  left: -70px;
  top: 15px;
  border-radius: 5px;
  border: 1px solid #4c78ce;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(122, 122, 122, 0.2);
  color: #4c78ce;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  padding: 5px 10px;
}
.tq-tooltip-trigger:hover .tq-tooltip {
  display: block;
}
.tq-tooltip::before {
  content: "";
  position: absolute;
  top: 8px;
  right: -10px;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-right-color: #4c78ce;
  border-top-color: #4c78ce;
  background-color: white;
  z-index: 1;
}
.img-height {
  height: 16px;
}
.testscreen-custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
}

.testscreen-modal-content-1 {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 38%;
  max-height: 80%;
  padding: 23px;
  position: relative;
  width: 650px;
}

.IncfontSize {
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: #eef3f7;
}

.not-allowed {
  cursor: not-allowed;
}

.selected-card-section {
  border-radius: 7px !important;
  border: 1px solid #84bae7 !important;
  background: rgba(235, 245, 255, 0.98) !important;
}
.unselected-card-section{
  border-radius: 7px !important;
  border: 1px solid #ffffff !important;
  background: rgba(235, 245, 255, 0.98) !important;
}

.cq-left-container {
  width: 100%;
  border-radius: 5px;
  height: calc(100vh - 80px);
  margin-right: 10px;
}

.cq-right-container {
  width: 30%;
  background: var(--bg-white);
  box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.5);
  border-radius: 8px;
  height: calc(100vh - 80px);
}

.cq-main-container {
  height: 100vh;
  padding: 10px;
  background: url("../../assets/newimgs/loginmainbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}

.cq-main-head {
  font-weight: 600;
  font-size: 16px;
  color: #464667;
}

.cq-clock {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 13px;
  padding: 5px 10px;
  color: rgba(69, 69, 69, 0.8);
}

.cq-top-div {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(196, 196, 196, 0.5);
  border-radius: 5px;
  padding: 15px;
  font-weight: 400;
  font-size: 18px;
  color: #464667;
}

.cq-green-border {
  border-left: 4px solid #4ef085;
  border-radius: 2px;
  width: 6px;
}

.cq-ques-no {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.cq-ques-text {
  font-weight: 400;
  font-size: 14px;
  color: #1a4164;
}

.cq-tag {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
}

.cq-ques-under {
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #585858;
}

.cq-ques {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;

  color: #3a3c40;
}

.cq-inside-box {
  background: #ffffff;
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: #20639b;
  padding: 5px 12px;
  white-space: nowrap;
}

.cq-footer {
  background: #ffffff;
  /* box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.5); */
  border-top: 1px solid #d7d7d7;
  border-radius: 0px 0px 8px 8px;
  padding: 15px;
}

.cq-main-test-box {
  height: calc(100vh - 136px);
  padding: 10px;
}

.cq-main-test {
  background-color: white;
  border-radius: 4px;
}

.cq-main-test-left-cont {
  border-radius: 5px;
  background: #eef3f7;
  padding: 15px;
  overflow: auto;
}
.cmpltedsucc-icon {
  position: absolute;
  top: -65px;
  left: 39%;
}

.cq-main-test-right-cont {
  background: #ffffff;
  /* box-shadow: 0px 0px 14px rgba(196, 196, 196, 0.5); */
  border-radius: 5px;
}

.cq-sub-head {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #3a3c40;
}

.cq-testcase-box {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.5);
  border-radius: 8px;
  padding: 10px 5px;
}

.cq-many-testCase-left {
  min-height: auto;
  max-height: calc(100vh - 635px);
  overflow: auto;
  background: #fbfafa;
  border-radius: 5px;
}

.cq-many-testCase-sel-text {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #3379b3;
  background: #eef2f5;
  border-radius: 5px 5px 0px 0px;
}

.cq-many-testCase-text {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #7a7a7a;
}

.cq-many-testCase-right {
  min-height: auto;
  max-height: calc(100vh - 635px);
  overflow: auto;
}

.cq-TC-card {
  border-radius: 5px;
  position: relative;
  background: #eef3f7;
}

.cq-preview-head {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
  font-family: "Montserrat", sans-serif;
}

.cq-lang-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #20639b;
  padding: 5px 15px;
  background: #ffffff;
  border: 0.8px solid #20639b;
  border-radius: 42px;
}

.cq-selectLang-cont {
  position: absolute;
  border-radius: 5px;
  border: 1px solid #2988e638;
  background: #f9f9f9;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  width: 100%;
  height: fit-content;
  max-height: 163px;
  overflow: auto;
}

.cq-selectLang-cont .form-group {
  margin-bottom: 0px;
}

.cq-selectLang-cont .custom-control {
  padding: 5px 10px 5px 35px;
}

.cq-selectLang-cont .custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 31px;
  padding-top: 11px;
}

.cq-selectLang-cont .custom-control-label {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #3a3c40;
  line-height: 22px;
  width: 100%;
  word-break: break-all;
}

.cq-selectLang-cont .custom-control-label::before {
  border: 2px solid #20639b;
}

.cq-selectLang-cont
  .custom-radio.b-custom-control-sm
  .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  border: 1px solid #20639b;
  border-radius: 50%;
}

.cq-selectLang-cont
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-width: 4px;
  border-color: #fff;
  background-color: #20639b;
}

.cq-selectLang-cont
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none !important;
  border: 2px solid #20639b;
  border-radius: 50%;
}

/* .cq-typeradio:nth-child(odd) {
  background: #f4f4f4;
}

.cq-typeradio:nth-child(even) {
  background: #fdfdfd;
} */

.cq-langline {
  border: 1px solid #20639b;
  height: 20px;
}

.cq-accept-lang {
  font-weight: 400;
  font-size: 12px;
  color: #464667;
}

.cq-lang-text {
  font-weight: 500;
  font-size: 12px;
  color: #464667;
}

.cq-black {
  height: calc(100vh - 300px);
  overflow: auto;
  background: #3c3c3c;
  box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.5);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: white;
}

.cq-test-result {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
}

.cq-test-result-cont {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.5);
  border-radius: 8px;
  padding: 7px;
}

.cq-topRed {
  background: #eb0002;
  box-shadow: 0px 1px 8px rgba(196, 196, 196, 0.25);
  border-radius: 5px 5px 0px 0px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  font-family: "montserrat", sans-serif;
  padding: 6px 13px;
}

.cq-topGreen {
  background: #63cf30;
  box-shadow: 0px 1px 8px rgba(196, 196, 196, 0.25);
  border-radius: 5px 5px 0px 0px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  font-family: "montserrat", sans-serif;
  padding: 6px 13px;
}

.tc-many-trialCase-left {
  min-height: auto;
  max-height: calc(100vh - 635px);
  overflow: auto;
  background: #ffffff;
  border: 1px solid #c4c4c440;
  border-radius: 3px;
}

.tc-many-trialCase-sel-text {
  background: #eef2f5;
}

.tc-greenText {
  font-weight: 500;
  font-size: 12px;
  color: #1cb803;
}

.tc-many-testCase-text-disable {
  border: 0.2px solid #fbfafa !important;
  background-color: #fbfafa !important;
}

.tc-many-testCase-text-grey {
  font-size: 12px;
  font-weight: 400;
  color: #7a7a7a;
  font-family: "lato", sans-serif;
  border: 0.2px solid #c4c4c440;
  border-radius: 4px;
  padding: 0 10px;
  height: 35px;
}

.tc-many-testCase-text-green {
  font-size: 12px;
  font-weight: 400;
  color: #038e00;
  font-family: "lato", sans-serif;
  border: 0.2px solid #c4c4c440;
  border-radius: 4px;
  padding: 0 10px;
  height: 35px;
}

.tc-many-testCase-right {
  min-height: auto;
  max-height: calc(100vh - 635px);
  overflow: auto;
}

.cq-code-progress {
  position: absolute;
  right: -16px;
  top: 250px;
  z-index: 1000;
}

.cq-slider {
  position: absolute;
  top: 0px;
  right: -10px;
  width: 450px;
  background: #ffffff;
  filter: drop-shadow(-2px 0px 14px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  height: calc(100vh - 75px);
  z-index: 11;
  padding: 15px;
}

.cq-overview-text {
  font-weight: 600;
  font-size: 14px;
  color: #696969;
}

.cq-overview-card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: 1px solid transparent;
}

.cq-sel-overview-card {
  background: rgba(235, 245, 255, 0.32);
  border: 1px solid #84bae7;
  border-radius: 3px;
}

.cq-overview-subtext {
  font-weight: 400;
  font-size: 13px;
  color: #464667;
}

.cq-green-overview {
  background: #269c32;
  border-radius: 5px;
  width: fit-content;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
  border: 3px solid white;
}

.cq-red-overview {
  background: #eb0002;
  border-radius: 5px;
  width: fit-content;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
  border: 3px solid white;
}
.cq-red-overview-secselc {
  /* border-radius: 5px;
  width: fit-content;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff; */
  border: 3px solid rgba(235, 245, 255, 0.98) !important;
  ;
}
.cq-grey-overview {
  background: #e5e5e5;
  border-radius: 3px;
  width: fit-content;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
  border: 1px solid transparent;
}

.gap-5 {
  margin: 7px -3px;
}

.cq-selected-green-outer {
  border-radius: 5px;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
  background: #269c32;
  width: fit-content;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
}

.cq-selected-red-outer {
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  background: #eb0002;
  width: fit-content;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
}

.cq-green-inner {
  background: #269c32;
  border-radius: 3px;
  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  padding: 8px 10px;
}

.cq-red-inner {
  background: #eb0002;
  border-radius: 3px;
  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  padding: 8px 10px;
  border: 1px solid transparent;
}

.cq-slider-overflow {
  height: calc(100vh - 150px);
  overflow: auto;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(100%, 0);
}

.cq-modal-text {
  color: #72738e;
  font-weight: 600;
  font-size: 13px;
}

.cq-modal-subtext {
  color: #8a8b8c;
  font-weight: 400;
  font-size: 13px;
}

.cq-modalheading {
  color: #545454;
  font-weight: 600;
  font-size: 14px;
}

.cq-quitProgress {
  position: absolute;
  left: -44px;
  top: -76px;
}

.cq-tryQuestionBtn {
  color: #4c78ce;
  font-size: 14px;
  font-weight: 500;
}
.lang-change-toast {
  background-color: #f00;
  color: #fff;
  padding: 3px;
  border-radius: 5px;
  position: fixed;
  top: 13px;
  right: 125px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cq-sub-testcase {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
}
.cq-tag-inputs {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7a7a7a;
}
.test-timerdiv-submit {
  /* padding: 6px 11px 8px 2px; */
  width: 415px;
  height: 65px;
  right: 20px;
  top: 20px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25), 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: absolute;
  top: 48px;
  right: 53px;
  z-index: 7;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.drop-enter-active,
.drop-leave-active {
  transition: all 0.5s ease;
}
.drop-enter,
.drop-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.drop-enter-to,
.drop-leave {
  transform: translateY(0);
  opacity: 1;
}
.bluebtns .b-overlay .spinner-border {
  height: 1.5rem;
  width: 1.5rem;
}
.save-modal-head {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
}
.save-modal-subtxt {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #344054;
}
.cancel-btn {
  padding: 3px 20px;
  width: 87px;
  height: 32px;
  border: 1px solid #20639b;
  border-radius: 40px;
  background: #ffffff;
  color: #20639b;
}
.save-txt {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.x-mark-modal {
  position: absolute;
  right: 20px;
  top: 15px;
}
</style>
