export default {
  methods: {
    errorToast(message) {
      this.$bvToast.toast(message, {
        title: "Confirmation",
        solid: true,
        autoHideDelay: 5000,
        headerClass: "danger-toast-headerclass",
        bodyClass: "toast-bodyclass",
        toastClass: "toast-toastclass"
      });
      this.toasttext = "";
    },
    successToast(message) {
      this.$bvToast.toast(message, {
        title: "Confirmation",
        solid: true,
        autoHideDelay: 5000,
        headerClass: "toast-headerclass",
        bodyClass: "toast-bodyclass",
        toastClass: "toast-toastclass"
      });
      this.toasttext = "";
    }
  }
};
