<template>
  <div>
    <div v-for="item in pageItem" :key="item['questionKey']">
      <div>
        <McqAnswercard
          :data="item"
          :showindex="parseInt(item.id) + 1"
          v-if="item['questionKey'].slice(0, 4) == 'MCQU'"
        />
      </div>
      <div >
        <MtfAnswercard 
          :data="item"
          @changeOption="refreshComponent"
          :showindex="parseInt(item.id) + 1"
          v-if="item['questionKey'].slice(0, 4) == 'MAFO' && isChildVisible"
        />
       </div>
        <div>
         <LongAnswerCard
          :data="item"
          :showindex="parseInt(item.id) + 1"
          v-if="item['questionKey'].slice(0, 4) == 'LONG'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import McqAnswercard from "../components/answerCards/mcq.vue";
import MtfAnswercard from "../components/answerCards/matchTheFollowing.vue";
import LongAnswerCard from "../components/answerCards/longAnswers.vue"
import { mapGetters } from "vuex";
export default {
  name: "QuestionCard",
  data() {
    return {
      perPage: 2,
      currentPage: 1,
      card_active: -1,
      isChildVisible: true,
      longQuestionData: [],
      shortQuestionData: [],
      mcqdata: [],
      ComprehensiveData: [],
      fillInTheBlanksData: [],
      MatchTheFollowingData: [],
      questionDee: [],
      items: [],
      // pageItem: [],
      len: 0,
      // Payload For Answers API in the making
      payload: {
        topicID: "",
        testID: "",
        testResponse: [],
      },
      // Section Wise Data
      sectionsData: {
        sectionID: "",
        sectionResponse: [],
      },
      // Question Wise Data
      sectionWiseQuestionData: {
        questionKey: "",
        answer: "",
      },
      count: 0,
    };
  },
  components: {
    McqAnswercard,
    MtfAnswercard,
    LongAnswerCard
  },
  watch: {
    getPageItem(newValue, oldValue) {
      if (newValue != oldValue) {
        this.pageItem = newValue;
      }
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },

    ...mapGetters("questionVUEX", {
      questionData: "getQuestionData",
      currentQuestionData: "getCurrentQuestionData",
      isLoadingStatusOne: "getiIsLoadingStatusOne",
      testResponse: "getTestResponse",
      answerData: "getAnswerData",
      countLength: "getCountLengthnData",
      pageItem: "getPageItem",
      lastQuestionIndex: "getLastQuestionIndex",
      noOfQues: "getNoOfQues",
    }),
    ...mapGetters("sectionVUEX", {
      selectedSection: "getSelectedSection",
      testDetails: "getTestDetails",
    }),
  },

  methods: {
    refreshComponent(){
      this.isChildVisible = false; 
      this.$nextTick(() => {
        this.isChildVisible = true; 
      });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>

<style>
@import "../assets/globalcss/style.css";

.questionCardDown {
  display: none;
}

@media screen and (max-width: 480px) {
  .text-opps {
    display: none;
  }

  .questionCardDown {
    display: block;
  }

  .questionCardPart2 {
    border: 1px solid blue !important;
    margin-bottom: 30px;
  }
}

.next-prev-row1 {
  position: absolute;
  width: 100%;
  bottom: 2px;
  padding-top: 6px;
  padding-bottom: 2px;
  box-shadow: -1px -5px 4px -7px #000000;
}
</style>
