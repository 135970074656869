<template>
  <div>
    <div class="test-section-tabs">
      <div class="d-flex sectionMainTab mb-2">
        <div
          v-for="(section, index) in sectionDetails"
          :key="section.sectionID"
        >
          <div
            @click="sectionSelect(section, index)"
            class="p-2 d-flex align-items-center justify-content-center"
            :class="
              selectedSection.sectionID == section.sectionID
                ? 'sectionSelectedTab'
                : 'sectionNotSelectedTab'
            "
            v-if="section.sectionResponse.length > 0"
            :id="section.sectionID"
          >
            <div class="sec-name text-truncate">
              {{ section.sectionName }}
            </div>
            <div>
              <div
                class="
                d-flex
                justify-content-center
                align-items-center
             
                ml-1
                "
                :class="
                  totalNumnerOfCodingQuestions(section.sectionResponse) ==
                  totalNumberOfQuestionsAttempted(section)
                    ? 'simp'
                    : 'nonselected-attemptdesign' && selectedSection.sectionID == section.sectionID? 'attmptdesign':'nonselected-attemptdesign' 
                "
              >
                {{ totalNumnerOfCodingQuestions(section.sectionResponse) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-sectionBody">
        <div>
          <div class="sf-code-ques-overflow">
            <div v-if="selectedSection.sectionName == 'programming'">
             
              <div
                v-for="(data, i) in selectedSection.sectionResponse"
                :key="i"
                class="sf-ques-card"
              >
                <div style="width: 90%;">
                  <div class="sf-ques-text d-flex">
                    <div class="coding-prgmng-heading"
                      >Section {{ i + 1 }} :</div
                    >
                   <div style="width: 80%;">
                    <b>{{ data.sectionName }}</b>
                   </div>
                  </div>
                </div>
                <div
                  class="w-100 d-flex align-items-center justify-content-between"
                >
                  <div class="sf-ques-subtext mt-4">
                    No.of questions : <b>{{ data.sectionResponse.length }}</b>
                  </div>
                  <div class="bluebtns">
                    <b-button
                      @click="openquesview(data, i)"
                      variant="primary"
                      class="px-30"
                      >Start</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <QuestionCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionCard from "../components/QuestionCard.vue";
import { mapGetters } from "vuex";
export default {
  name: "SectionFilling",
  components: {
    QuestionCard,
  },
  data() {
    return {
      loopVar: 10,
      codingSection: false,
      extractedValues: [],
      showCode: false,
      dataforcodingtest: [],
      sftrue: false,
      value: 1,
      max: 7,
      items: [],
      codingTest: false,
    };
  },
  // Passing testID
  mounted: function() {
    setTimeout(() => {
      this.sectionSelect(this.sectionDetails[this.sectionId], this.sectionId);
    }, 1000);
  },

  computed: {
    ...mapGetters("testVUEX", {
      questionData: "getQuestionData",
      sectionDetails: "getSectionDetails",
      selectedSection: "getSelectedSection",
    }),
    ...mapGetters("sectionVUEX", {
      SectionAPI: "SectionAPIStatus",
      testDetails: "getTestDetails",
      isLoadingStatus: "getIsLoadingStatus",
      tabIndexFromStore: "getTabIndex",
      sectionId: "getsectionId",
    }),
    ...mapGetters("questionVUEX", {
      questionAnswerStatus: "getQuestionAnswerStatus",
      attemptedCount: "getAttemptedAnswersCount",
      noOfQues: "getNoOfQues",
      lastQuestionIndex: "getLastQuestionIndex",
      noPage: "getNoPage",
      noOfPages: "getNoOfPages",
    }),
    tabIndex: {
      get() {
        return this.tabIndexFromStore;
      },
      set(newTabIndex) {
        return newTabIndex;
      },
    },
  },
  watch: {
    selectedSection(oldValue, newValue) {
      if (oldValue != newValue) {
        this.$nextTick(() => {
          document
            .getElementById(this.selectedSection.sectionID)
            .scrollIntoView({ behaviour: "smooth", align: true });
        });
      }
    },
  },
  methods: {
    totalNumnerOfCodingQuestions(data) {
      try {
        if (data[0].codingSection) {
          let len = data.reduce(
            (totalLength, item) => totalLength + item.sectionResponse.length,
            0
          );
          return len;
        } else {
          return data.length;
        }
      } catch (error) {
        return data.length;
      }
    },
    totalNumberOfQuestionsAttempted(data) {
      let totalAnswerCount = 0;
      if (!data.codingSection) {
        data.sectionResponse.map((data) => {
          if (data.answerStatus.attempted) {
            totalAnswerCount++;
          }
        });
      }
      if (data.codingSection) {
        data.sectionResponse.map((data) => {
          data.sectionResponse.map((data) => {
            if (data.answerStatus.attempted) {
              totalAnswerCount++;
            }
          });
        });
      }
      return totalAnswerCount;
    },
    showCodingTest() {
      this.codingTest = true;
      this.showCode = true;
    },
    openquesview(data, i) {
      this.$store.commit("sectionVUEX/saveSelectedCodingSectionIndex", i);
      this.$store.commit("codingVUEX/openCodingSection", true);
      this.$store.commit("testVUEX/storeQuetionsForProgramming", i);
    },
    sectionSelect(selectedSection, index1) {
      this.codingSection = selectedSection.codingSection;
      this.extractedValues = selectedSection.sectionResponse;
      this.dataforcodingtest = selectedSection.sectionResponse;
      // filling logic
      let data = {
        value: index1,
        status: "set",
      };
      this.$store.commit("sectionVUEX/saveTabIndex", data);
      this.$store.commit("questionVUEX/saveNoPage", "set");
      this.$store.commit("testVUEX/saveSelectedSection", selectedSection);
      this.$store.commit("sectionVUEX/prevnextbtnstatus", index1);
      this.$store.commit("questionVUEX/lastQuestionIndex", 0);
      this.items = this.questionData;
      this.$store.commit(
        "questionVUEX/noOfPages",
        Math.ceil(this.items.length / this.testDetails.config.quesPerScreen)
      );
      for (let i in this.questionData) {
        this.items[i].id = i;
      }
      this.$store.commit("questionVUEX/allItem", this.items);
      this.$store.commit(
        "questionVUEX/noOfQues",
        this.testDetails.config.quesPerScreen
      );
      this.$store.commit(
        "questionVUEX/pageItems",
        this.items.slice(0, this.noOfQues)
      );
      this.len = parseInt(this.lastQuestionIndex) + parseInt(this.noOfQues);
      let payload = {};
      payload.testID = this.testDetails.testID;
      payload.topicID = this.testDetails.topicID;
      payload.groupID = this.testDetails.groupID;
      payload.attemptNo = this.testDetails.attemptNo;
      this.$store.dispatch("testVUEX/testCount", payload);
      if (selectedSection.sectionName != "programming") {
        this.$nextTick(() => {
          document
            .getElementById(
              "ques" +
                this.questionData[0].questionKey +
                selectedSection.sectionID
            )
            .scrollIntoView({ behaviour: "smooth", align: true });
        });
      }
      setTimeout(() => {
        document
          .querySelector(".selected-section-progress")
          .scrollIntoView({ behaviour: "smooth", align: true });
      }, 100);
    },
  },
};
</script>

<style>
.sectionSelectedTab {
  background: #20639b;
  border-radius: 5px;
  max-width: 130px;
  min-width: 130px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.sectionMainTab {
  border-radius: 5px;
  background: #eef3f7;
  width: 100%;
  overflow: auto;
}

.sectionMainTab::-webkit-scrollbar-thumb {
  background-color: #4c78ce !important; /* Color of the scrollbar thumb */
  border-radius: 5px 5px 0 0 !important;
}

.sectionMainTab::-webkit-scrollbar {
  width: 1px !important;
  height: 4px !important;
}

.sectionNotSelectedTab {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7a7a7a;
  max-width: 130px;
  min-width: 130px;
  cursor: pointer;
}

.sf-sectionBody {
  height: calc(100vh - 220px);
  overflow: auto;
}

.sectionBody {
  height: calc(100vh - 185px);
  overflow: auto;
}

.simp {
  background: #63cf30;
  color: #ffffff;
  border-radius: 10px;
  height: 19px;
  font-weight: var(--weight-M);
  font-size: var(--size-10);
  width: 19px;
}

.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  box-shadow: inset 0 0 5px #c9c9c9;
  border-radius: 10px;
  width: 159px;
  height: 11px;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 1373px;
  height: 11px;
  background-color: #dddddd !important;
  box-shadow: inset 0 3px 5px #c9c9c9;
  border-radius: 10px;
}

.section-container {
  border-bottom: 1px solid;
  height: 80px;
  width: 100%;
  padding-bottom: 1px;
}

.section-filling-row {
  margin-left: 0px !important;
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  align-items: center !important;
}

.section-button-content {
  display: inline-block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.section-btn {
  background-color: #c92b2b;
  color: #ffffff;
  margin-top: 5px;
  width: 140px;
  height: 50px;
  margin-right: 15px;
  font-weight: 500;
  border-color: transparent;
}

.sec-btn {
  margin-right: 20px;
}

#myProgress {
  width: 100%;
  background-color: #ddd;
}

#myBar {
  width: 5%;
  height: 100%;
  background-color: #269c32;
}

.progress {
  height: 37px !important;
  width: 108px;
  border: 1px solid #707070;
  background-color: #ffffff !important;
}

.progress-bar {
  background-color: #269c32 !important;
}

.section-name {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 4px;
  padding-left: 33px;

  font-size: 16px;
  color: #4b4d4e;
  font-weight: 400;
  cursor: pointer;
}

.progress-col {
  position: relative;
}

.progress-col span {
  display: inline-block;
  margin-left: -10px;
  margin-top: 1px;
  width: 8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.section-second-row {
  flex-wrap: nowrap !important;
}

.section-second-row .col-2 {
  flex: 0 0 10.333333%;
  max-width: 10.333333%;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .section-second-row .col-2 {
    flex: 0 0 10.333333%;
    max-width: 36.333333%;
    text-align: center;
  }

  .progress {
    height: 35px !important;
  }

  .section-name {
    padding-top: 3px;
  }

  .section-filling-row {
    height: 10vh;
  }

  .section-container {
    border-bottom: none;
    height: 10.4vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 320px) {
  .section-second-row .col-2 {
    max-width: 42.333333%;
  }

  .progress {
    height: 30px !important;
  }
}

@media screen and (min-width: 280px) and (max-width: 298px) {
  .section-second-row .col-2 {
    max-width: 51.333333%;
  }

  .progress {
    height: 30px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .section-second-row .col-2 {
    max-width: 29.333333%;
  }
}

@media screen and (min-width: 500px) and (max-width: 599px) {
  .section-second-row .col-2 {
    max-width: 29.333333%;
  }
}

.tabs .nav {
  flex-flow: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.sec-name {
  white-space: nowrap;
}

.sf-prog-head {
  font-weight: 600;
  font-size: 22px;
  color: #333333;
}

.sf-ques-card {
  position: relative;
  background: #eef3f7;
  border-radius: 14px;
  margin: 12px 0px;
  padding: 12px 15px;
}

.sf-ques-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
  gap: 10px;
}

.sf-ques-subtext {
  font-weight: 400;
  font-size: 14px;
  color: #545454;
}

.sf-fix-addbtn {
  position: absolute;

  right: 12px;
  bottom: 12px;
}
</style>
